import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Stack } from "@mui/material";
import { CellClickedEvent, ColDef, SortChangedEvent } from "ag-grid-community";
import moment from "moment";
import { parse, stringify } from "qs";
import { mixed, object, string } from "yup";

import { BasicTable, SearchField } from "src/components/commons";
import useOpenModal from "src/hooks/useOpenModal";
import usePopContentsPlcmtList, {
  PopContentsPlcmt,
  PopContentsPlcmtFields,
  PopContentsPlcmtListParams,
} from "src/hooks/apis/popcontents/use-popcontents-plcmt-list";
import usePopContentsServiceList, {
  PopContentsService,
} from "src/hooks/apis/popcontents/use-popcontents-service-list";

import { SEARCH_PARAMS_VALIDATOR } from "../const";
import CreatePlcmtModal from "./CreatePlcmtModal";
import UpdatePlcmtModal from "./UpdatePlcmtModal";

const defaultSearchParams: PopContentsPlcmtListParams = {
  query: "",
  page_no: 1,
  page_size: 20,
  orders: ["-created_at"],
};

const searchParamsValidationSchema = object({
  query: string(),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidOrders", SEARCH_PARAMS_VALIDATOR.orders),
});

const getColumnDef = (serviceList: PopContentsService[]): ColDef[] => [
  {
    headerName: "팝콘텐츠 지면키",
    field: "id",
    sortable: true,
  },
  {
    headerName: "page ID",
    field: "page_id",
    sortable: true,
  },
  {
    headerName: "앱 매체 명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "매체 키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "Channel ID",
    field: "channel_id",
    sortable: true,
  },
  {
    headerName: "외주사 명",
    field: "partner_name",
    sortable: true,
  },
  {
    headerName: "Service ID",
    field: "service_id",
    sortable: true,
    valueFormatter: (params) => {
      const serviceId = params.value as number;
      return serviceList.find((service) => service.id === serviceId)?.desc || "-";
    },
  },
  {
    headerName: "랜딩 URL",
    field: "landing_url",
    sortable: true,
  },
  {
    headerName: "campaign IDs",
    field: "campaign_ids",
    sortable: false,
    valueFormatter: (params) => {
      const campaignIds = params.value as number[];
      return campaignIds.join(", ");
    },
  },
  {
    headerName: "placement IDs",
    field: "ssp_plcmt_ids",
    sortable: false,
    valueFormatter: (params) => {
      const plcmtIds = params.value as string[];
      return plcmtIds.join(", ");
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sort: "desc",
    sortable: true,
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD");
    },
  },
];

export default function Placements() {
  const [selectedPlcmt, setSelectedPlcmt] = useState<PopContentsPlcmt | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState<PopContentsPlcmtListParams>(() => {
    const parsedSearch = parse(location.search, { ignoreQueryPrefix: true, comma: true });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        query: validatedParams.query || defaultSearchParams.query,
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  const [tempQuery, setTempQuery] = useState(params.query || "");

  // 플레이스먼트 목록 조회
  const plcmtListQuery = usePopContentsPlcmtList(params);

  // 서비스 목록 조회
  const serviceListQuery = usePopContentsServiceList();

  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/popcontents/placements" + searchParams, {
      replace: true,
    });
  }, [params, navigate]);

  const handleChangeQuery = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTempQuery(e.target.value);
  }, []);

  const handleSearchQuery = useCallback(() => {
    setParams((prev) => ({ ...prev, query: tempQuery, page_no: 1 }));
  }, [tempQuery]);

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) =>
        sort === "desc" ? `-${colId}` : colId
      ) as PopContentsPlcmtFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: plcmtListQuery.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [
      handleChangePage,
      handleChangePageSize,
      plcmtListQuery.data.pages,
      params.page_no,
      params.page_size,
    ]
  );

  const [openCreatePlcmtModal, onShowCreatePlcmtModal, onCloseCreatePlcmtModal] =
    useOpenModal(null);

  const [openUpdatePlcmtModal, onShowUpdatePlcmtModal, onCloseUpdatePlcmtModal] =
    useOpenModal(null);

  const handleOpenUpdatePlcmtModal = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();

      if (e.colDef.field !== "activated" && e.colDef.field !== "id") {
        onShowUpdatePlcmtModal(e.event, null);
        setSelectedPlcmt(e.data);
      }
    },
    [onShowUpdatePlcmtModal]
  );

  const handleCloseUpdatePlcmtModal = useCallback(() => {
    onCloseUpdatePlcmtModal();
    setSelectedPlcmt(null);
  }, [onCloseUpdatePlcmtModal]);

  const handleRefetchPlcmtList = useCallback(async () => {
    await plcmtListQuery.refetch();
  }, [plcmtListQuery]);

  return (
    <Box sx={{ mt: "2rem" }}>
      <Stack
        className="ssp-tools"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <SearchField
          sx={{ width: 400 }}
          label="검색"
          placeholder="page id, 앱 매체 명으로 검색 가능합니다."
          value={tempQuery}
          onChange={handleChangeQuery}
          onClickSearchButton={handleSearchQuery}
        />
        <Button variant="outlined" onClick={onShowCreatePlcmtModal}>
          지면 등록
        </Button>
      </Stack>
      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={plcmtListQuery.data.rows}
        columnDefs={getColumnDef(serviceListQuery.data)}
        {...paginationInfo}
        onCellClicked={handleOpenUpdatePlcmtModal}
        onSortChanged={handleSortChange}
      />
      {openCreatePlcmtModal.isOpen && (
        <CreatePlcmtModal
          serviceList={serviceListQuery.data}
          onCreate={handleRefetchPlcmtList}
          onClose={onCloseCreatePlcmtModal}
        />
      )}
      {openUpdatePlcmtModal.isOpen && selectedPlcmt && (
        <UpdatePlcmtModal
          plcmt={selectedPlcmt}
          serviceList={serviceListQuery.data}
          onUpdate={handleRefetchPlcmtList}
          onClose={handleCloseUpdatePlcmtModal}
        />
      )}
    </Box>
  );
}
