import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";
import { parseCookies } from "nookies";

import API from "src/utils/api";

import useApiError from "../useApiError";
import { PopContentsPartner } from "./use-popcontents-partner-list";

export type UpdatePopContentsPartnerPayload = Pick<
  PopContentsPartner,
  "id" | "commission_rate" | "white_ips"
>;

type UpdatePopContentsPartnerMutationKey = "operation/popcontents/update-partner";

type UpdatePopContentsPartnerMutationFn = MutationFunction<
  AxiosResponse,
  UpdatePopContentsPartnerPayload
>;

type UpdatePopContentsPartnerMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, UpdatePopContentsPartnerPayload>,
  "mutationKey" | "mutationFn"
>;

export default function useUpdatePopContentsPartner(
  options?: UpdatePopContentsPartnerMutationOptions
) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<UpdatePopContentsPartnerMutationKey>(
    () => "operation/popcontents/update-partner",
    []
  );

  const mutateFn = useCallback<UpdatePopContentsPartnerMutationFn>((values) => {
    const cookie = parseCookies();
    const { id, ...payload } = values;

    return API.consoleV3.put(`/v3/popcontents/partners/${id}`, payload, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
