import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { array, number, object, string } from "yup";

import { MultipleSelect, Select, TextField } from "src/components/commons";
import { PopContentsService } from "src/hooks/apis/popcontents/use-popcontents-service-list";
import useToast from "src/hooks/useToast";
import useGetAppPlacementList, {
  AppPlacement,
} from "src/hooks/apis/placements/useGetAppPlacementList";
import useCreatePopContentsPlcmt, {
  CreatePopContentsPlcmtPayload,
} from "src/hooks/apis/popcontents/use-create-popcontents-plcmt";
import usePopContentsAppList, {
  PopContentsApp,
} from "src/hooks/apis/popcontents/use-popcontents-app-list";
import usePopContentsPartnerList, {
  PopContentsPartner,
} from "src/hooks/apis/popcontents/use-popcontents-partner-list";
import { STATUS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";

const NO_VALUE = -1;
const DEFAULT_SERVICE_ID = 1; // 룰렛

const createPlcmtFormSchema = object({
  partner_id: number()
    .required("외주사를 선택해 주세요.")
    .notOneOf([NO_VALUE], "외주사를 선택해 주세요."),
  media_key: string().required("앱 매체를 선택해 주세요."),
  service_id: number()
    .required("서비스 ID를 선택해 주세요.")
    .notOneOf([NO_VALUE], "서비스 ID를 선택해 주세요."),
  page_id: string().required("page ID를 입력해 주세요."),
  landing_url: string()
    .matches(REG_EXP.domain, "URL 형식을 확인해 주세요.")
    .required("페이지의 URL을 입력해 주세요."),
  ssp_plcmt_ids: array().of(string()).min(1, "페이지에 연결된 플레이스먼트를 모두 선택해 주세요. "),
  campaign_ids: array().of(string().matches(REG_EXP.number, "숫자로 입력해 주세요.")),
});

export default function CreatePlcmtModal(props: {
  serviceList: PopContentsService[];
  onCreate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { serviceList, onCreate, onClose } = props;

  const toast = useToast();

  const { mutate: createPlcmt, isLoading: isCreating } = useCreatePopContentsPlcmt();

  const initialValues = useMemo<CreatePopContentsPlcmtPayload>(
    () => ({
      partner_id: NO_VALUE,
      media_key: "",
      service_id: DEFAULT_SERVICE_ID, // 룰렛
      page_id: "",
      landing_url: "",
      ssp_plcmt_ids: [],
      campaign_ids: [],
    }),
    []
  );

  const handleCreate = (values: CreatePopContentsPlcmtPayload) => {
    createPlcmt(
      {
        ...values,
        campaign_ids: values.campaign_ids.map((id) => Number(id)),
      },
      {
        onSuccess: () => {
          toast.success("저장되었습니다.");
          onClose();
          onCreate();
        },
      }
    );
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isValid,
    setValues,
    getFieldProps,
  } = useFormik({
    initialValues,
    onSubmit: handleCreate,
    validationSchema: createPlcmtFormSchema,
  });

  const [campaignIdsStr, setCampaignIdsStr] = useState(initialValues.campaign_ids.join(","));

  // 외주사 목록 조회
  const partnerListQuery = usePopContentsPartnerList({
    page_no: 1,
    page_size: 10000,
    orders: ["name"],
  });

  // 앱 매체 목록 조회
  const appListQuery = usePopContentsAppList(
    {
      partner_id: values.partner_id,
      page_no: 1,
      page_size: 10000,
      orders: ["name"],
    },
    {
      enabled: values.partner_id !== NO_VALUE,
    }
  );

  // 플레이스먼트 목록 조회
  const { data: placementData, isLoading: isPlcmtListLoading } = useGetAppPlacementList({
    mediaKeys: values.media_key ? [values.media_key] : [String(NO_VALUE)],
    orders: ["name"],
    status: STATUS.ACTIVE,
  });

  const onChangePartner = useCallback(
    (_: SyntheticEvent<unknown>, v: PopContentsPartner | null) => {
      setValues((prev) => ({
        ...prev,
        partner_id: v?.id || NO_VALUE,
        media_key: "",
        ssp_plcmt_ids: [],
      }));
    },
    [setValues]
  );

  const onChangeApp = useCallback(
    (_: SyntheticEvent<unknown>, v: PopContentsApp | null) => {
      setValues((prev) => ({
        ...prev,
        media_key: v?.key || "",
        ssp_plcmt_ids: [],
      }));
    },
    [setValues]
  );

  const onChangeServiceId = useCallback(
    (event: SelectChangeEvent<PopContentsService["id"]>) => {
      const serviceId = Number(event.target.value);

      if (Number.isInteger(serviceId)) {
        setFieldValue("service_id", serviceId);
      }
    },
    [setFieldValue]
  );

  const onChangePlcmtList = useCallback(
    (values: AppPlacement[]) => {
      setFieldValue(
        "ssp_plcmt_ids",
        values.map((plcmt) => plcmt.id)
      );
    },
    [setFieldValue]
  );

  const onChangeCampaignIds = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setCampaignIdsStr(value);
      setFieldValue(
        "campaign_ids",
        value === ""
          ? []
          : value
              .split(",")
              .map((id) => id.trim())
              .filter((id) => id !== "")
      );
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="create-popcontents-plcmt-modal-title">지면 등록</DialogTitle>
      <DialogContent className="create-popcontents-plcmt-modal-dialog-content">
        <Stack
          component="form"
          id="create-popcontents-plcmt-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <Autocomplete
            options={partnerListQuery.data.rows}
            getOptionLabel={(partner) => partner.name}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="외주사"
                placeholder="외주사를 선택해 주세요."
                required
                error={shouldErrorShows("partner_id", touched, errors)}
                helperText={getHelperText("partner_id", touched, errors)}
              />
            )}
            value={
              partnerListQuery.data.rows.find((partner) => partner.id === values.partner_id) || null
            }
            onChange={onChangePartner}
            onBlur={handleBlur("partner_id")}
            disabled={partnerListQuery.isLoading || isCreating}
          />

          {/* 앱 매체 명 */}
          <Autocomplete
            options={appListQuery.data.rows}
            getOptionLabel={(app) => app.name}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="앱 매체"
                placeholder="외주사 선택 후 앱 매체를 선택해 주세요."
                required
                error={shouldErrorShows("media_key", touched, errors)}
                helperText={getHelperText("media_key", touched, errors)}
              />
            )}
            value={appListQuery.data.rows.find((app) => app.key === values.media_key) || null}
            onChange={onChangeApp}
            onBlur={handleBlur("media_key")}
            disabled={values.partner_id === NO_VALUE || appListQuery.isLoading || isCreating}
          />

          <Stack direction="row" alignItems="center" spacing={2}>
            {/* 매체 키 */}
            <TextField required label="매체 키" value={values.media_key} aria-readonly disabled />

            {/* Channel ID */}
            <TextField
              required
              label="Channel ID"
              value={
                appListQuery.data.rows.find((app) => app.key === values.media_key)?.channel_id || ""
              }
              aria-readonly
              disabled
            />
          </Stack>

          {/* 서비스 아이디 */}
          <Select
            label="Service ID"
            value={values.service_id}
            onChange={onChangeServiceId}
            onBlur={handleBlur("service_id")}
            required
          >
            {serviceList.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.desc}
              </MenuItem>
            ))}
          </Select>

          {/* Page ID */}
          <TextField
            required
            label="Page ID"
            placeholder="page ID를 입력해 주세요."
            {...getFieldProps("page_id")}
            error={shouldErrorShows("page_id", touched, errors)}
            helperText={getHelperText("page_id", touched, errors)}
            disabled={isCreating}
          />

          {/* 랜딩 URL */}
          <TextField
            required
            label="랜딩 URL"
            placeholder="페이지의 URL을 입력해 주세요."
            {...getFieldProps("landing_url")}
            error={shouldErrorShows("landing_url", touched, errors)}
            helperText={getHelperText("landing_url", touched, errors)}
            disabled={isCreating}
          />

          {/* 플레이스먼트 명 */}
          <MultipleSelect
            className="field"
            options={placementData.placements}
            label="플레이스먼트"
            placeholder="페이지에 연결된 플레이스먼트를 모두 선택해 주세요."
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onChangePlcmtList}
            disabled={values.media_key === "" || isPlcmtListLoading}
            value={values.ssp_plcmt_ids.map(
              (id) => placementData.placements.find((plcmt) => plcmt.id === id) as AppPlacement
            )}
          />

          {/* 캠페인 ID */}
          <TextField
            label="Campaign ID"
            placeholder="Campaign ID를 다수 등록할 경우 comma(,) 로 구분해서 등록해주세요."
            value={campaignIdsStr}
            onChange={onChangeCampaignIds}
            onBlur={handleBlur("campaign_ids")}
            disabled={isCreating}
            error={shouldErrorShows("campaign_ids", touched, errors)}
            helperText={getHelperText("campaign_ids", touched, errors)}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit">
          취소
        </Button>
        <Button
          type="submit"
          form="create-popcontents-plcmt-modal"
          disabled={!isValid || isCreating}
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
