import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Stack } from "@mui/material";
import { CellClickedEvent, ColDef, ICellRendererParams, SortChangedEvent } from "ag-grid-community";
import moment from "moment";
import { parse, stringify } from "qs";
import { mixed, object, string } from "yup";

import { BasicTable, SearchField } from "src/components/commons";
import useOpenModal from "src/hooks/useOpenModal";
import usePopContentsAppList, {
  PopContentsApp,
  PopContentsAppFields,
  PopContentsAppListParams,
} from "src/hooks/apis/popcontents/use-popcontents-app-list";

import { SEARCH_PARAMS_VALIDATOR } from "../const";
import CreateAppModal from "./CreateAppModal";
import UpdateAppModal from "./UpdateAppModal";

const defaultSearchParams: PopContentsAppListParams = {
  query: "",
  page_no: 1,
  page_size: 20,
  orders: ["-created_at"],
};

const searchParamsValidationSchema = object({
  query: string(),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidOrders", SEARCH_PARAMS_VALIDATOR.orders),
});

const columnDef: ColDef[] = [
  {
    headerName: "업체 명",
    field: "company_name",
    sortable: true,
  },
  {
    headerName: "앱 매체 명",
    field: "name",
    sortable: true,
  },
  {
    headerName: "매체 키",
    field: "key",
    sortable: true,
  },
  {
    headerName: "대행 수수료율",
    field: "commission_rate",
    sortable: true,
    valueFormatter: (params) => {
      const rate = params.value as number;
      return `${rate}%`;
    },
  },
  {
    headerName: "외주사 명",
    field: "partner_name",
    sortable: true,
  },
  {
    headerName: "Channel ID",
    field: "channel_id",
    sortable: true,
  },
  {
    headerName: "endpoint URL",
    field: "endpoint",
    sortable: false,
    valueFormatter: (params) => {
      const { url } = params.value as PopContentsApp["endpoint"];
      return url;
    },
  },
  {
    headerName: "플레이스먼트 수",
    field: "plcmt_cnt",
    sortable: true,
    width: 200,
    cellRenderer: (params: ICellRendererParams) => {
      const { name } = params.data;
      const count = params.value as number;
      const to = `/operation/popcontents/placements?media_key=all&query=${encodeURI(name)}`;

      return <Link to={to}>{count}개</Link>;
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sort: "desc",
    sortable: true,
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD");
    },
  },
];

export default function Apps() {
  const [selectedApp, setSelectedApp] = useState<PopContentsApp | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState<PopContentsAppListParams>(() => {
    const parsedSearch = parse(location.search, { ignoreQueryPrefix: true, comma: true });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        query: validatedParams.query || defaultSearchParams.query,
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  const [tempQuery, setTempQuery] = useState(params.query || "");

  const appListQuery = usePopContentsAppList(params);

  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/popcontents/apps" + searchParams, {
      replace: true,
    });
  }, [params, navigate]);

  const handleChangeQuery = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTempQuery(e.target.value);
  }, []);

  const handleSearchQuery = useCallback(() => {
    setParams((prev) => ({ ...prev, query: tempQuery, page_no: 1 }));
  }, [tempQuery]);

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId)) as PopContentsAppFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: appListQuery.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [
      handleChangePage,
      handleChangePageSize,
      appListQuery.data.pages,
      params.page_no,
      params.page_size,
    ]
  );

  const [openCreateAppModal, onShowCreateAppModal, onCloseCreateAppModal] = useOpenModal(null);

  const [openUpdateAppModal, onShowUpdateAppModal, onCloseUpdateAppModal] = useOpenModal(null);

  const handleOpenUpdateAppModal = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();

      if (e.colDef.field !== "activated" && e.colDef.field !== "id") {
        onShowUpdateAppModal(e.event, null);
        setSelectedApp(e.data);
      }
    },
    [onShowUpdateAppModal]
  );

  const handleCloseUpdateAppModal = useCallback(() => {
    onCloseUpdateAppModal();
    setSelectedApp(null);
  }, [onCloseUpdateAppModal]);

  const handleRefetchAppList = useCallback(async () => {
    await appListQuery.refetch();
  }, [appListQuery]);

  return (
    <Box sx={{ mt: "2rem" }}>
      <Stack
        className="ssp-tools"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <SearchField
          sx={{ width: 400 }}
          label="검색"
          placeholder="업체 명, 매체 명 으로 검색 가능합니다."
          value={tempQuery}
          onChange={handleChangeQuery}
          onClickSearchButton={handleSearchQuery}
        />
        <Button variant="outlined" onClick={onShowCreateAppModal}>
          팝콘텐츠 앱 등록
        </Button>
      </Stack>
      <BasicTable
        getRowId={(params) => `${params.data.partner_id}/${params.data.key}`}
        animateRows
        rowData={appListQuery.data.rows}
        columnDefs={columnDef}
        {...paginationInfo}
        onCellClicked={handleOpenUpdateAppModal}
        onSortChanged={handleSortChange}
      />
      {openCreateAppModal.isOpen && (
        <CreateAppModal onCreate={handleRefetchAppList} onClose={onCloseCreateAppModal} />
      )}
      {openUpdateAppModal.isOpen && selectedApp && (
        <UpdateAppModal
          app={selectedApp}
          onUpdate={handleRefetchAppList}
          onClose={handleCloseUpdateAppModal}
        />
      )}
    </Box>
  );
}
