import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import { parseCookies } from "nookies";

import API from "src/utils/api";

import useApiError from "../useApiError";

export enum PopContentsTransactionStatus {
  요청 = 0,
  실패 = 25,
  취소 = 30,
  완료 = 99,
}

export type PopContentsTransaction = {
  adid: string;
  campaign_id: number;
  campaign_name: string;
  channel_id: number;
  created_at: number;
  media_key: string;
  media_name: string;
  no: number;
  page_id: string;
  partner_id: number;
  partner_name: string;
  plcmt_id: string;
  reward: number;
  service_id: number;
  status: PopContentsTransactionStatus;
  updated_at: number;
  usn: string;
};

export type PopContentsTransactionHistoryParams = {
  id: string;
  since: string;
  until: string;
  page_no?: number;
  page_size?: number;
  orders?: string[];
};

type PopContentsTransactionHistoryData = {
  message: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: PopContentsTransaction[];
  };
};

type PopContentsTransactionHistoryQueryKey = [
  "operation/popcontents/transaction-history",
  PopContentsTransactionHistoryParams
];

type PopContentsTransactionHistoryQueryFn = QueryFunction<
  AxiosResponse<PopContentsTransactionHistoryData>,
  PopContentsTransactionHistoryQueryKey
>;

type PopContentsTransactionHistoryQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsTransactionHistoryData>,
    AxiosError,
    AxiosResponse<PopContentsTransactionHistoryData>,
    PopContentsTransactionHistoryQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsTransactionHistoryData = {
  message: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function usePopContentsTransactionHistory(
  params: PopContentsTransactionHistoryParams,
  options?: PopContentsTransactionHistoryQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsTransactionHistoryQueryKey>(
    () => ["operation/popcontents/transaction-history", params],
    [params]
  );

  const queryFn = useCallback<PopContentsTransactionHistoryQueryFn>((context) => {
    const [, { id, ...rest }] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get(`/v3/customer-services/popcontents/${id}/transactions`, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params: rest,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
