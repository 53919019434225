import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Autocomplete, Box, Button, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { ColDef, ICellRendererParams, SortChangedEvent } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { parse, stringify } from "qs";
import { array, mixed, number, object, string } from "yup";

import { BasicTable, DateField, MultipleSelect, Select, TextField } from "src/components/commons";
import useToast from "src/hooks/useToast";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import usePopContentsCsList, {
  PopContentsCs,
  PopContentsCsFields,
  PopContentsCsListParams,
  PopContentsCsStatus,
} from "src/hooks/apis/popcontents/use-popcontents-cs-list";
import usePopContentsPartnerList, {
  PopContentsPartner,
} from "src/hooks/apis/popcontents/use-popcontents-partner-list";
import usePopContentsAppList, {
  PopContentsApp,
} from "src/hooks/apis/popcontents/use-popcontents-app-list";

import { CS_MAX_DATE, MIN_DATE, SEARCH_PARAMS_VALIDATOR } from "../const";

const NO_VALUE = -1;
const STATUS_ALL = 0;

const searchParamsValidationSchema = object({
  partner_id: string().matches(REG_EXP.number).required(),
  since: string().test("isValidSince", SEARCH_PARAMS_VALIDATOR.since(CS_MAX_DATE)),
  until: string().test("isValidUntil", SEARCH_PARAMS_VALIDATOR.until(CS_MAX_DATE)),
  media_keys: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
  status: string().oneOf([
    STATUS_ALL.toString(),
    ...Object.values(PopContentsCsStatus).map((value) => value.toString()),
  ]),
  query: string(),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
});

const defaultSearchParams: PopContentsCsListParams = {
  partner_id: NO_VALUE,
  since: moment().subtract(7, "d").format("YYYYMMDD"),
  until: moment().subtract(1, "d").format("YYYYMMDD"), // TODO: Cs는 오늘까지 조회 가능해야함
  media_keys: [],
  status: STATUS_ALL,
  query: "",
  page_no: 1,
  page_size: 20,
  orders: ["-created_at"],
};

type FormValues = Pick<PopContentsCsListParams, "partner_id" | "status" | "query"> & {
  media_keys: string[];
  since: Moment | null;
  until: Moment | null;
};

const formValidationSchema = object({
  partner_id: number()
    .required("외주사를 선택해 주세요.")
    .notOneOf([NO_VALUE], "외주사를 선택해 주세요."),
  since: mixed().required(),
  until: mixed().required(),
  media_keys: array().of(string().required()),
  status: number().required(),
  query: string(),
});

const STATUS_OPTIONS = [
  {
    label: "전체 상태",
    value: STATUS_ALL,
  },
  {
    label: "처리 중(접수)",
    value: PopContentsCsStatus.InProgress,
  },
  {
    label: "처리 완료",
    value: PopContentsCsStatus.Completed,
  },
];

export const columnDef: ColDef[] = [
  {
    headerName: "NO",
    field: "no",
    sortable: true,
  },
  {
    headerName: "문의 일자",
    field: "created_at",
    sort: "desc",
    sortable: true,
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY/MM/DD HH:mm:ss");
    },
  },
  {
    headerName: "앱 매체 명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "업체 명",
    field: "company_name",
    sortable: true,
  },
  {
    headerName: "ADID",
    field: "adid",
    sortable: true,
  },
  {
    headerName: "Channel ID",
    field: "channel_id",
    sortable: true,
  },
  {
    headerName: "Page ID",
    field: "page_id",
    sortable: true,
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
  },
  {
    headerName: "문의내용",
    field: "inquiry_content",
    sortable: false,
    cellRenderer: (params: ICellRendererParams) => {
      const { no, inquiry_content } = params.data as PopContentsCs;
      return (
        <Box component="span" sx={{ overflow: "hidden" }}>
          <Link
            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
            to={`/operation/popcontents/cs/${no}`}
          >
            {inquiry_content}
          </Link>
        </Box>
      );
    },
  },
];

export default function CsList() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  // cs 목록 query params
  const [params, setParams] = useState(() => {
    const parsedSearch = parse(location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        partner_id: Number(validatedParams.partner_id),
        since: moment(validatedParams.since).format("YYYYMMDD"),
        until: moment(validatedParams.until).format("YYYYMMDD"),

        media_keys: Array.isArray(validatedParams.media_keys)
          ? validatedParams.media_keys
          : typeof validatedParams.media_keys === "string"
          ? validatedParams.media_keys.split(",")
          : defaultSearchParams.media_keys,
        status: validatedParams.status
          ? Number(validatedParams.status)
          : defaultSearchParams.status,
        query: validatedParams.query || defaultSearchParams.query,
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      } as PopContentsCsListParams;
    } catch (error) {
      return defaultSearchParams;
    }
  });

  // search params 업데이트
  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/popcontents/cs" + searchParams, {
      replace: true,
    });
  }, [navigate, params]);

  const initialFormValues = useMemo<FormValues>(() => {
    return {
      partner_id: params.partner_id,
      since: moment(params.since).startOf("d"),
      until: moment(params.until).startOf("d"),
      media_keys: params.media_keys || [],
      status: params.status,
      query: params.query,
    };
  }, [params]);

  // 조회하기 버튼 핸들러
  const handleSubmitForm = (values: FormValues) => {
    setParams((prev) => ({
      ...prev,
      ...values,
      since: (values.since as Moment).format("YYYYMMDD"),
      until: (values.until as Moment).format("YYYYMMDD"),
      page_no: 1,
    }));
  };

  // formik
  const {
    setValues,
    setFieldValue,
    values,
    isValid,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: formValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  // CS 목록 조회 (외주사 아이디가 선택된 경우에만)
  const csListQuery = usePopContentsCsList(params, {
    enabled: params.partner_id !== NO_VALUE,
  });

  // 외주사 목록 조회
  const partnerListQuery = usePopContentsPartnerList({
    page_no: 1,
    page_size: 10000,
    orders: ["name"],
  });

  // 앱 매체 목록 조회
  const appListQuery = usePopContentsAppList(
    {
      partner_id: values.partner_id,
      page_no: 1,
      page_size: 10000,
      orders: ["name"],
    },
    {
      enabled: values.partner_id !== NO_VALUE,
      onSuccess: (response) => {
        const { rows } = response.data.data;

        if (values.media_keys.length <= 0) {
          setFieldValue(
            "media_keys",
            rows.map((app) => app.key)
          );
        }
      },
    }
  );

  const onChangePartner = useCallback(
    (_: SyntheticEvent<unknown>, v: PopContentsPartner | null) => {
      setValues((prev) => ({
        ...prev,
        partner_id: v?.id || NO_VALUE,
        media_keys: [],
      }));
    },
    [setValues]
  );

  const onChangeAppList = useCallback(
    (values: PopContentsApp[]) => {
      setFieldValue(
        "media_keys",
        values.map((app) => app.key)
      );
    },
    [setFieldValue]
  );

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(CS_MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            ...prev,
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            ...prev,
            since: value,
            until: newEndDate.isAfter(CS_MAX_DATE) ? CS_MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const handleChangeStatus = useCallback(
    (e: SelectChangeEvent<PopContentsCsStatus>) => {
      const { value } = e.target;

      setFieldValue("status", Number(value));
    },
    [setFieldValue]
  );

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId)) as PopContentsCsFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: csListQuery.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [
      params.page_no,
      params.page_size,
      csListQuery.data.pages,
      handleChangePage,
      handleChangePageSize,
    ]
  );

  const isLoading = csListQuery.isLoading || partnerListQuery.isLoading || appListQuery.isLoading;

  return (
    <Stack mt="2rem" mb="1rem" spacing={2}>
      <Stack component="form" onSubmit={handleSubmit} spacing={2}>
        {/* row1 */}
        <Stack direction="row" alignItems="start" spacing={2}>
          {/* 외주사 */}
          <Autocomplete
            options={partnerListQuery.data.rows}
            getOptionLabel={(partner) => partner.name || ""}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                sx={{ width: 300 }}
                label="외주사"
                placeholder="외주사를 선택해 주세요."
                required
                error={shouldErrorShows("partner_id", touched, errors)}
                helperText={getHelperText("partner_id", touched, errors)}
              />
            )}
            value={
              partnerListQuery.data.rows.find((partner) => partner.id === values.partner_id) || null
            }
            onChange={onChangePartner}
            onBlur={handleBlur("partner_id")}
            disabled={csListQuery.isLoading || partnerListQuery.isLoading}
          />

          {/* 앱 매체 명 */}
          <Box sx={{ width: 300 }}>
            <MultipleSelect
              options={appListQuery.data.rows}
              label="앱 매체 명"
              placeholder="앱 매체를 선택해 주세요."
              getOptionLabel={(app) => app.name}
              getOptionValue={(app) => app.key}
              onChange={onChangeAppList}
              value={appListQuery.data.rows.filter((app) => values.media_keys.includes(app.key))}
              disabled={isLoading || values.partner_id === NO_VALUE}
            />
          </Box>

          {/* 시작일 */}
          <Box sx={{ width: 200 }}>
            <DateField
              required
              label="시작일"
              minDate={MIN_DATE}
              maxDate={CS_MAX_DATE}
              value={values.since}
              onChange={handleChangeDates("since")}
              InputProps={{
                onBlur: handleBlur("since"),
                error: shouldErrorShows("since", touched, errors),
              }}
              disabled={csListQuery.isFetching}
            />
          </Box>

          {/* 종료일 */}
          <Box sx={{ width: 200 }}>
            <DateField
              required
              label="종료일"
              minDate={MIN_DATE}
              maxDate={CS_MAX_DATE}
              value={values.until}
              onChange={handleChangeDates("until")}
              InputProps={{
                onBlur: handleBlur("until"),
                error: shouldErrorShows("until", touched, errors),
              }}
              disabled={csListQuery.isFetching}
            />
          </Box>

          {/* 조회 버튼 */}
          <Button type="submit" variant="contained" disabled={!isValid || csListQuery.isFetching}>
            조회하기
          </Button>
        </Stack>

        {/* row2 */}
        <Stack direction="row" alignItems="start" spacing={2}>
          {/* 상태 항목 */}
          <Box sx={{ width: 300 }}>
            <Select
              label="상태"
              onChange={handleChangeStatus}
              value={values.status as number}
              disabled={csListQuery.isFetching}
            >
              {STATUS_OPTIONS.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* 검색 */}
          <TextField
            sx={{ width: 300, flexShrink: 0 }}
            label="검색"
            placeholder="검색어를 입력해 주세요."
            value={values.query}
            onChange={handleChange("query")}
            onBlur={handleBlur("query")}
            disabled={csListQuery.isFetching}
          />
        </Stack>

        <BasicTable
          getRowId={(params) => params.data.no}
          animateRows
          rowData={csListQuery.data.rows}
          columnDefs={columnDef}
          {...paginationInfo}
          onSortChanged={handleSortChange}
        />
      </Stack>
    </Stack>
  );
}
