import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import { AxiosError, AxiosResponse } from "axios";
import { parseCookies } from "nookies";

import API from "src/utils/api";

import useApiError from "../useApiError";

export type ReplyPopContentsCsValues = {
  no: string;
  response_content: string;
};

type ReplyPopContentsCsMutationKey = "operation/popcontents/reply-cs";
type ReplyPopContentsCsMutationFn = MutationFunction<AxiosResponse, ReplyPopContentsCsValues>;

type ReplyPopContentsCsMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, ReplyPopContentsCsValues>,
  "mutationKey" | "mutationFn"
>;

export default function useReplyPopContentsCs(options?: ReplyPopContentsCsMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<ReplyPopContentsCsMutationKey>(
    () => "operation/popcontents/reply-cs",
    []
  );

  const mutateFn = useCallback<ReplyPopContentsCsMutationFn>((values) => {
    const { no, ...rest } = values;
    const cookie = parseCookies();

    return API.consoleV3.put(`/v3/customer-services/popcontents/${no}`, rest, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
