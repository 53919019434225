import { useMemo, useCallback, ChangeEvent, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { object, string, number, array } from "yup";

import { TextField } from "src/components/commons";
import useToast from "src/hooks/useToast";
import useCreatePopContentsPartner, {
  CreatePopContentsPartnerPayload,
} from "src/hooks/apis/popcontents/use-create-popcontents-partner";
import { shouldErrorShows, getHelperText, REG_EXP } from "src/utils/form-helper";

export const NO_VALUE = -1;

const createPartnerFormSchema = object({
  name: string().required("외주사 업체 명을 입력해 주세요."),
  brn: string()
    .matches(REG_EXP.number, "사업자등록번호를 숫자로만 입력해 주세요.")
    .required("사업자등록번호를 숫자로만 입력해 주세요."),
  commission_rate: number()
    .required("수수료율을 입력해 주세요.")
    .notOneOf([NO_VALUE], "수수료율을 입력해 주세요."),
  white_ips: array().of(string()),
});

export default function CreatePartnerModal(props: {
  onCreate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { onCreate, onClose } = props;

  const toast = useToast();

  const { mutate: createPartner, isLoading } = useCreatePopContentsPartner();

  const initialValues = useMemo<CreatePopContentsPartnerPayload>(
    () => ({
      name: "",
      brn: "",
      commission_rate: 0,
      white_ips: [],
    }),
    []
  );

  const handleCreate = (values: CreatePopContentsPartnerPayload) => {
    createPartner(values, {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        onClose();
        onCreate();
      },
    });
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isValid,
    getFieldProps,
  } = useFormik({
    initialValues,
    onSubmit: handleCreate,
    validationSchema: createPartnerFormSchema,
  });

  const [commissionRateStr, setCommissionRateStr] = useState(String(initialValues.commission_rate));
  const [whiteIpsStr, setWhiteIpsStr] = useState(initialValues.white_ips.join(","));

  const onChangeBrn = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "" || REG_EXP.number.test(value)) {
        setFieldValue("brn", value);
      }
    },
    [setFieldValue]
  );

  const onChangeCommissionRate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "" || REG_EXP.numberInput.test(value)) {
        setCommissionRateStr(value);
        setFieldValue("commission_rate", value === "" ? NO_VALUE : parseFloat(value));
      }
    },
    [setFieldValue]
  );

  const onChangeWhiteIps = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setWhiteIpsStr(value);
      setFieldValue(
        "white_ips",
        value === ""
          ? []
          : value
              .split(",")
              .map((ip) => ip.trim())
              .filter((ip) => ip !== "")
      );
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="create-popcontents-partner-modal-title">외주사 등록</DialogTitle>
      <DialogContent className="create-popcontents-partner-modal-dialog-content">
        <Stack
          component="form"
          id="create-popcontents-partner-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 외주사 명 */}
          <TextField
            label="외주사 명"
            required
            placeholder="외주사 업체 명을 입력해주세요."
            {...getFieldProps("name")}
            error={shouldErrorShows("name", touched, errors)}
            helperText={getHelperText("name", touched, errors)}
            disabled={isLoading}
          />

          {/* 사업자등록번호 */}
          <TextField
            label="사업자등록번호"
            required
            placeholder="사업자등록번호를 숫자로만 입력해 주세요."
            value={values.brn}
            onChange={onChangeBrn}
            onBlur={handleBlur("brn")}
            error={shouldErrorShows("brn", touched, errors)}
            helperText={getHelperText("brn", touched, errors)}
            disabled={isLoading}
          />

          {/* 외주사 수수료율 */}
          <TextField
            label="외주사 수수료율"
            required
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  %
                </Typography>
              ),
            }}
            placeholder="수수료율을 입력해 주세요."
            value={commissionRateStr}
            onChange={onChangeCommissionRate}
            onBlur={handleBlur("commission_rate")}
            error={shouldErrorShows("commission_rate", touched, errors)}
            helperText={
              getHelperText("commission_rate", touched, errors) ||
              "애드팝콘과 외주사 간의 수수료율 입니다."
            }
            disabled={isLoading}
          />

          {/* White IP */}
          <TextField
            label="White IP"
            placeholder="white IP를 다수 등록할 경우 comma(,) 로 구분해서 등록해주세요."
            value={whiteIpsStr}
            onChange={onChangeWhiteIps}
            onBlur={handleBlur("white_ips")}
            disabled={isLoading}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit">
          취소
        </Button>
        <Button
          type="submit"
          form="create-popcontents-partner-modal"
          disabled={!isValid || isLoading}
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
