import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import { AxiosError, AxiosResponse } from "axios";
import { parseCookies } from "nookies";

import API from "src/utils/api";

import useApiError from "../useApiError";
import { PopContentsPlcmt } from "./use-popcontents-plcmt-list";

export type CreatePopContentsPlcmtPayload = Pick<
  PopContentsPlcmt,
  | "partner_id"
  | "media_key"
  | "service_id"
  | "page_id"
  | "landing_url"
  | "ssp_plcmt_ids"
  | "campaign_ids"
>;

type CreatePopContentsPlcmtMutationKey = "operation/popcontents/create-plcmt";

type CreatePopContentsPlcmtMutationFn = MutationFunction<
  AxiosResponse,
  CreatePopContentsPlcmtPayload
>;

type CreatePopContentsPlcmtMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, CreatePopContentsPlcmtPayload>,
  "mutationKey" | "mutationFn"
>;

export default function useCreatePopContentsPlcmt(options?: CreatePopContentsPlcmtMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<CreatePopContentsPlcmtMutationKey>(
    () => "operation/popcontents/create-plcmt",
    []
  );

  const mutateFn = useCallback<CreatePopContentsPlcmtMutationFn>((values) => {
    const cookie = parseCookies();
    const { partner_id, media_key, ...payload } = values;

    return API.consoleV3.post(
      `/v3/popcontents/partners/${partner_id}/media/${media_key}/placements`,
      payload,
      {
        headers: {
          "x-api-type": "1",
          Authorization: `Bearer ${cookie.access_token || ""}`,
        },
      }
    );
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
