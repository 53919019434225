import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import { AxiosError, AxiosResponse } from "axios";
import { parseCookies } from "nookies";

import API from "src/utils/api";

import useApiError from "../useApiError";
import { PopContentsCsStatus } from "./use-popcontents-cs-list";

export type PopContentsCsDetailParams = {
  no: string;
};

export type PopContentsCsDetail = {
  adid: string;
  channel_id: number;
  company_key: string;
  company_name: string;
  created_at: number;
  email: string;
  inquiry_content: string;
  media_key: string;
  media_name: string;
  no: number;
  page_id: string;
  partner_id: number;
  partner_name: string;
  response_content: string;
  status: PopContentsCsStatus;
  updated_at: number;
  usn: string;
};

type PopContentsCsDetailData = {
  code: number;
  text: string;
  data: PopContentsCsDetail | null;
};

type PopContentsCsDetailQueryKey = ["operation/popcontents/cs-detail", PopContentsCsDetailParams];

type PopContentsCsDetailQueryFn = QueryFunction<
  AxiosResponse<PopContentsCsDetailData>,
  PopContentsCsDetailQueryKey
>;

type PopContentsCsDetailQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsCsDetailData>,
    AxiosError,
    AxiosResponse<PopContentsCsDetailData>,
    PopContentsCsDetailQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsCsDetailData = {
  code: 200,
  text: "OK",
  data: null,
};

export default function usePopContentsCsDetail(
  params: PopContentsCsDetailParams,
  options?: PopContentsCsDetailQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsCsDetailQueryKey>(
    () => ["operation/popcontents/cs-detail", params],
    [params]
  );

  const queryFn = useCallback<PopContentsCsDetailQueryFn>((context) => {
    const [, { no }] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get(`/v3/customer-services/popcontents/${no}`, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    ...options,
    onError: (error) => {
      handleError(error);

      if (options?.onError) {
        options?.onError(error);
      }
    },
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
