import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export enum PopContentsCsStatus {
  InProgress = 10,
  Completed = 50,
}

export type PopContentsCs = {
  adid: string;
  channel_id: number;
  company_key: string;
  company_name: string;
  created_at: string;
  email: string;
  inquiry_content: string;
  media_key: string;
  media_name: string;
  no: number;
  page_id: string;
  partner_id: number;
  partner_name: string;
  response_content: string;
  status: number;
  updated_at: string;
  usn: string;
};

export type PopContentsCsFields = keyof PopContentsCs | `-${keyof PopContentsCs}`;

export type PopContentsCsListParams = {
  partner_id: number;
  since: string; // yyyyMMdd
  until: string; // yyyyMMdd
  media_keys?: string[];
  status?: PopContentsCsStatus | 0;
  query?: string;
  page_no?: number;
  page_size?: number;
  orders?: PopContentsCsFields[];
};

type PopContentsCsListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: PopContentsCs[];
  };
};

type PopContentsCsListQueryKey = ["operation/popcontetns/cs-list", PopContentsCsListParams];

type PopContentsCsListQueryFn = QueryFunction<
  AxiosResponse<PopContentsCsListData>,
  PopContentsCsListQueryKey
>;

type PopContentsCsListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsCsListData>,
    AxiosError,
    AxiosResponse<PopContentsCsListData>,
    PopContentsCsListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsCsListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function usePopContentsCsList(
  params: PopContentsCsListParams,
  options?: PopContentsCsListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsCsListQueryKey>(
    () => ["operation/popcontetns/cs-list", params],
    [params]
  );

  const queryFn = useCallback<PopContentsCsListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/customer-services/popcontents", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
