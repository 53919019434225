import moment, { Moment } from "moment";
import { TestContext } from "yup";
import { AnyObject } from "yup/lib/types";

export const MIN_DATE = moment("2024-12-01").startOf("d");
export const MAX_DATE = moment().startOf("d");
export const MAX_RANGE = 60;

const isValidDateRange = (date: Moment, maxDate: Moment) => {
  return date.isSameOrAfter(MIN_DATE) && date.isSameOrBefore(maxDate);
};

export const SEARCH_PARAMS_VALIDATOR = {
  list(value?: string | string[]) {
    if (!value) {
      return true;
    }

    return Array.isArray(value) || typeof value === "string";
  },
  since: (maxDate: Moment) => (value: string | undefined, context: TestContext<AnyObject>) => {
    if (!value) {
      return false;
    }

    const since = moment(value);
    const until = moment(context.parent.until);

    // MIN~MAX 사이 && sameOrBefore endDate && endDate - startDate < MAX_RANGE
    if (
      since.isValid() &&
      until.isValid() &&
      isValidDateRange(since, maxDate) &&
      isValidDateRange(until, maxDate) &&
      since.isSameOrBefore(until)
    ) {
      const daysDiff = until.diff(since, "d");
      return daysDiff < MAX_RANGE;
    }

    return false;
  },
  until: (maxDate: Moment) => (value: string | undefined, context: TestContext<AnyObject>) => {
    if (!value) {
      return false;
    }

    const since = moment(context.parent.until);
    const until = moment(value);

    // MIN~MAX 사이 && sameOrBefore endDate && endDate - startDate < MAX_RANGE
    if (
      since.isValid() &&
      until.isValid() &&
      isValidDateRange(since, maxDate) &&
      isValidDateRange(until, maxDate) &&
      since.isSameOrBefore(until)
    ) {
      const daysDiff = until.diff(since, "d");
      return daysDiff < MAX_RANGE;
    }

    return false;
  },
};
