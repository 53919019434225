import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { number, object, string } from "yup";

import { Select, TextField } from "src/components/commons";
import useToast from "src/hooks/useToast";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import useUpdatePopContentsApp from "src/hooks/apis/popcontents/use-update-popcontents-app";
import { PopContentsApp } from "src/hooks/apis/popcontents/use-popcontents-app-list";

type UpdatePopContentsAppFormValues = {
  // form use only
  company_name: string;
  name: string;
  partner_name: string;

  // readonly
  partner_id: number;
  key: string;

  // editable
  channel_id: number;
  commission_rate: number;
  url: string;
  method: string;
};

const NO_VALUE = -1;

const updateAppFormSchema = object({
  // readonly
  company_name: string().required(),
  name: string().required(),
  partner_name: string().required(),

  // readonly
  partner_id: number().required(),
  key: string().required(),

  // editable
  channel_id: number()
    .required("외주사에서 발급한 channel ID를 입력해 주세요.")
    .notOneOf([NO_VALUE], "외주사에서 발급한 channel ID를 입력해 주세요."),
  commission_rate: number()
    .required("수수료율을 입력해 주세요.")
    .notOneOf([NO_VALUE], "수수료율을 입력해 주세요."),
  method: string().required().oneOf(["GET", "POST"]),
  url: string()
    .matches(REG_EXP.domain, "URL 형식을 확인해 주세요.")
    .required("매체사에 요청할 end point URL을 입력해주세요."),
});

export default function UpdateAppModal(props: {
  app: PopContentsApp;
  onUpdate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { app, onUpdate, onClose } = props;

  const toast = useToast();

  const { mutate: updateApp, isLoading } = useUpdatePopContentsApp();

  const initialValues = useMemo<UpdatePopContentsAppFormValues>(
    () => ({
      company_name: app.company_name,
      name: app.name,
      partner_name: app.partner_name,

      partner_id: app.partner_id,
      key: app.key,

      channel_id: app.channel_id,
      commission_rate: app.commission_rate,
      method: app.endpoint.method,
      url: app.endpoint.url,
    }),
    [app]
  );

  const [commissionRateStr, setCommissionRateStr] = useState(String(initialValues.commission_rate));

  const handleCreate = ({
    // not in use
    company_name,
    name,
    partner_name,

    // required values
    method,
    url,
    ...values
  }: UpdatePopContentsAppFormValues) => {
    updateApp(
      {
        endpoint: { method, url },
        ...values,
      },
      {
        onSuccess: () => {
          toast.success("저장되었습니다.");
          onClose();
          onUpdate();
        },
      }
    );
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isValid,
    getFieldProps,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleCreate,
    validationSchema: updateAppFormSchema,
  });

  const onChangeChannelId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "" || REG_EXP.number.test(value)) {
        setFieldValue("channel_id", value === "" ? NO_VALUE : parseInt(value));
      }
    },
    [setFieldValue]
  );

  const onChangeCommissionRate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "" || REG_EXP.numberInput.test(value)) {
        setCommissionRateStr(value);
        setFieldValue("commission_rate", value === "" ? NO_VALUE : parseFloat(value));
      }
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="update-popcontents-app-modal-title">팝콘텐츠 앱 매체 수정</DialogTitle>
      <DialogContent className="update-popcontents-app-modal-dialog-content">
        <Stack
          component="form"
          id="update-popcontents-app-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <TextField label="업체 명" required readOnly disabled value={values.company_name} />

          {/* 앱 매체 명 */}
          <TextField label="앱 매체 명" required readOnly disabled value={values.name} />

          {/* 매체 키 */}
          <TextField required label="매체 키" value={values.key} aria-readonly disabled />

          {/* 외주사 명 */}
          <TextField label="외주사 명" required readOnly disabled value={values.partner_name} />

          {/* 채널 ID */}
          <TextField
            required
            label="Channel ID"
            placeholder="외주사에서 발급한 channel ID를 입력해 주세요."
            value={values.channel_id === NO_VALUE ? "" : String(values.channel_id)}
            onChange={onChangeChannelId}
            onBlur={handleBlur("channel_id")}
            error={shouldErrorShows("channel_id", touched, errors)}
            helperText={getHelperText("channel_id", touched, errors)}
            disabled={isLoading}
          />

          {/* 대행 수수료율 */}
          <TextField
            label="대행 수수료율"
            required
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  %
                </Typography>
              ),
            }}
            placeholder="수수료율을 입력해 주세요."
            value={commissionRateStr}
            onChange={onChangeCommissionRate}
            onBlur={handleBlur("commission_rate")}
            error={shouldErrorShows("commission_rate", touched, errors)}
            helperText={
              getHelperText("commission_rate", touched, errors) ||
              "매체사와 애드팝콘간의 수수료율을 의미합니다."
            }
            disabled={isLoading}
          />

          <Stack direction="row" alignItems="start" spacing={3}>
            {/* method */}
            <Select
              label="method"
              {...getFieldProps("method")}
              required
              fullWidth={false}
              sx={{ width: 90, flexShrink: 0 }}
              disabled={isLoading}
            >
              {["GET", "POST"].map((method) => (
                <MenuItem key={method} value={method}>
                  {method}
                </MenuItem>
              ))}
            </Select>

            {/* endpoint */}
            <TextField
              sx={{ flexGrow: 1 }}
              required
              label="endpoint"
              placeholder="매체사에 요청할 end point URL을 입력해 주세요."
              {...getFieldProps("url")}
              error={shouldErrorShows("url", touched, errors)}
              helperText={getHelperText("url", touched, errors)}
              disabled={isLoading}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit" disabled={isLoading}>
          취소
        </Button>
        <Button
          type="submit"
          form="update-popcontents-app-modal"
          disabled={!isValid || isLoading || !dirty}
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
