import {
  MouseEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Box,
  Button,
  Container,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { ColDef, GridApi } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { parse, stringify } from "qs";
import { useRecoilValue } from "recoil";
import { array, mixed, number, object, string } from "yup";

import { viewerCompany } from "src/atoms/viewerCompany";
import { BasicTable, DateField, MultipleSelect, Select, TextField } from "src/components/commons";
import useToast from "src/hooks/useToast";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";
import usePopContentsReport, {
  PopContentsReportParams,
  PopContentsReportRow,
} from "src/hooks/apis/report/use-popcontents-report";
import usePopContentsPartnerList, {
  PopContentsPartner,
} from "src/hooks/apis/popcontents/use-popcontents-partner-list";
import usePopContentsAppList, {
  PopContentsApp,
} from "src/hooks/apis/popcontents/use-popcontents-app-list";
import usePopContentsServiceList, {
  PopContentsService,
} from "src/hooks/apis/popcontents/use-popcontents-service-list";

import { MAX_DATE, MIN_DATE, SEARCH_PARAMS_VALIDATOR } from "./const";

const searchParamsValidationSchema = object({
  partner_id: string().matches(REG_EXP.number).required(),
  since: string().test("isValidSince", SEARCH_PARAMS_VALIDATOR.since(MAX_DATE)),
  until: string().test("isValidUntil", SEARCH_PARAMS_VALIDATOR.until(MAX_DATE)),
  company_key: string(),
  media_keys: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.list),
  service_id: string().matches(REG_EXP.number),
});

const NO_VALUE = -1;
const DEFAULT_SERVICE_ID = 0; // 전체 선택

const defaultSearchParams: PopContentsReportParams = {
  partner_id: NO_VALUE,
  since: moment().subtract(6, "d").format("YYYYMMDD"),
  until: moment().format("YYYYMMDD"),
  company_key: "",
  media_keys: [],
  service_id: DEFAULT_SERVICE_ID,
};

type FormValues = Pick<PopContentsReportParams, "partner_id" | "company_key"> & {
  since: Moment | null;
  until: Moment | null;
  media_keys: string[];
  service_id: number;
};

const formValidationSchema = object({
  partner_id: number()
    .required("외주사를 선택해 주세요.")
    .notOneOf([NO_VALUE], "외주사를 선택해 주세요."),
  since: mixed().required(),
  until: mixed().required(),
  company_key: string(),
  media_keys: array().of(string().required()),
  service_id: number().required(),
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 3,
});

const getColumnDef = (serviceList: PopContentsService[]): ColDef[] => [
  {
    headerName: "일자",
    field: "report_date",
    valueFormatter: (params) => {
      return moment(params.value).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "앱 매체 명",
    field: "media_name",
  },
  {
    headerName: "서비스 ID",
    field: "service_id",
    valueFormatter: (params) => {
      const serviceId = params.value as number;
      return serviceList.find((service) => service.id === serviceId)?.desc || "-";
    },
  },
  {
    headerName: "페이지 유저 수",
    field: "page_users",
  },
  {
    headerName: "페이지 조회 수",
    field: "page_views",
  },
  {
    headerName: "캠페인 플레이 유저 수",
    field: "campaign_play_users",
  },
  {
    headerName: "캠페인 플레이 횟수",
    field: "campaign_play_views",
  },
  {
    headerName: "캠페인 완료 유저 수",
    field: "campaign_complete_users",
  },
  {
    headerName: "캠페인 완료 횟수",
    field: "campaign_complete_views",
  },
  {
    headerName: "캠페인 광고 매출",
    field: "media_cost",
    valueFormatter: (params) => {
      return formatter.format(params.value);
    },
  },
];

export default function PopContentsReport() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const { key: companyKey } = useRecoilValue(viewerCompany);
  const [gridApi, setGridApi] = useState<GridApi>();

  const [params, setParams] = useState(() => {
    const parsedSearch = parse(location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        partner_id: Number(validatedParams.partner_id),
        since: moment(validatedParams.since).format("YYYYMMDD"),
        until: moment(validatedParams.until).format("YYYYMMDD"),
        company_key: validatedParams.company_key || "",
        media_keys: Array.isArray(validatedParams.media_keys)
          ? validatedParams.media_keys
          : typeof validatedParams.media_keys === "string"
          ? validatedParams.media_keys.split(",")
          : defaultSearchParams.media_keys,
        service_id: defaultSearchParams.service_id || DEFAULT_SERVICE_ID,
      } as PopContentsReportParams;
    } catch (error) {
      return defaultSearchParams;
    }
  });

  // search params 업데이트
  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/report/popcontents" + searchParams, {
      replace: true,
    });
  }, [navigate, params]);

  const initialFormValues = useMemo<FormValues>(() => {
    return {
      partner_id: params.partner_id,
      since: moment(params.since).startOf("d"),
      until: moment(params.until).startOf("d"),
      company_key: params.company_key,
      media_keys: params.media_keys || [],
      service_id: params.service_id || DEFAULT_SERVICE_ID,
    };
  }, [params]);

  // 조회하기 버튼 핸들러
  const handleSubmitForm = (values: FormValues) => {
    setParams((prev) => ({
      ...prev,
      ...values,
      since: (values.since as Moment).format("YYYYMMDD"),
      until: (values.until as Moment).format("YYYYMMDD"),
    }));
  };

  // formik
  const { setValues, setFieldValue, values, isValid, errors, touched, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialFormValues,
      onSubmit: handleSubmitForm,
      validationSchema: formValidationSchema,
      validateOnMount: true,
      enableReinitialize: true,
    });

  const hasPartnerId = params.partner_id !== NO_VALUE;
  const hasCompanyKey = Boolean(params.company_key);
  const hasMediaKeys = Array.isArray(params.media_keys) && params.media_keys.length > 0;
  const reportQueryEnabled = hasPartnerId && (hasCompanyKey || hasMediaKeys);

  // 리포트 조회
  const reportQuery = usePopContentsReport(params, {
    enabled: reportQueryEnabled,
  });

  // 외주사 목록 조회
  const partnerListQuery = usePopContentsPartnerList({
    page_no: 1,
    page_size: 10000,
    orders: ["name"],
  });

  // 앱 매체 목록 조회
  const appListQuery = usePopContentsAppList(
    {
      company_key: companyKey,
      partner_id: values.partner_id,
      page_no: 1,
      page_size: 10000,
      orders: ["name"],
    },
    {
      enabled: values.partner_id !== NO_VALUE,
      onSuccess: (response) => {
        const { rows } = response.data.data;

        if (values.media_keys.length <= 0) {
          setValues((prev) => ({
            ...prev,
            company_key: companyKey, // 사용자의 회사키
            media_keys: rows.map((app) => app.key),
          }));
        }
      },
    }
  );

  // 서비스 목록 조회
  const serviceListQuery = usePopContentsServiceList();

  const onChangePartner = useCallback(
    (_: SyntheticEvent<unknown>, v: PopContentsPartner | null) => {
      setValues((prev) => ({
        ...prev,
        partner_id: v?.id || NO_VALUE,
        company_key: "",
        media_keys: [],
      }));
    },
    [setValues]
  );

  const onChangeAppList = useCallback(
    (values: PopContentsApp[]) => {
      setValues((prev) => {
        const allMediaSelected = values.length === appListQuery.data.rows.length;

        return {
          ...prev,
          company_key: allMediaSelected
            ? companyKey // 사용자의 회사키
            : "",
          media_keys: values.map((app) => app.key),
        };
      });
    },
    [setValues, appListQuery.data.rows, companyKey]
  );

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            ...prev,
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            ...prev,
            since: value,
            until: newEndDate.isAfter(MAX_DATE) ? MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const onChangeServiceId = useCallback(
    (event: SelectChangeEvent<PopContentsService["id"]>) => {
      const serviceId = Number(event.target.value);

      if (Number.isInteger(serviceId)) {
        setFieldValue("service_id", serviceId);
      }
    },
    [setFieldValue]
  );

  const canExport = gridApi && !reportQuery.isLoading && reportQuery.data.length > 0;

  const onExport = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault();

      if (canExport) {
        gridApi.exportDataAsExcel({
          columnGroups: true,
          fileName: `팝콘텐츠_리포트(${params.since}-${params.until}).xlsx`,
        });
      }
    },
    [canExport, gridApi, params.since, params.until]
  );

  const serviceList = useMemo(
    () => [{ id: DEFAULT_SERVICE_ID, desc: "전체" }, ...serviceListQuery.data],
    [serviceListQuery.data]
  );

  const getRowId = useCallback((row: PopContentsReportRow) => {
    const { partner_id, report_date, channel_id, service_id, page_id, media_key } = row;
    const rowId = `${partner_id}-${report_date}-${media_key}-${channel_id}-${service_id}-${page_id}`;
    return rowId;
  }, []);

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        팝콘텐츠 리포트
      </Typography>

      <Paper className="content" elevation={2}>
        <Stack mt="2rem" mb="1rem" spacing={2}>
          {/* 검색폼 */}
          <Stack component="form" onSubmit={handleSubmit} spacing={2}>
            <Stack direction="row" alignItems="start" spacing={2}>
              {/* 외주사 */}
              <Autocomplete
                options={partnerListQuery.data.rows}
                getOptionLabel={(partner) => partner.name || ""}
                renderInput={({ InputLabelProps, ...params }) => (
                  <TextField
                    {...params}
                    sx={{ width: 250 }}
                    label="외주사"
                    placeholder="외주사를 선택해 주세요."
                    required
                    error={shouldErrorShows("partner_id", touched, errors)}
                    helperText={getHelperText("partner_id", touched, errors)}
                  />
                )}
                value={
                  partnerListQuery.data.rows.find((partner) => partner.id === values.partner_id) ||
                  null
                }
                onChange={onChangePartner}
                onBlur={handleBlur("partner_id")}
                disabled={reportQuery.isLoading || partnerListQuery.isLoading}
              />

              {/* 앱 매체 명 */}
              <Box sx={{ width: 250 }}>
                <MultipleSelect
                  options={appListQuery.data.rows}
                  label="앱 매체 명"
                  placeholder="앱 매체를 선택해 주세요."
                  getOptionLabel={(app) => app.name}
                  getOptionValue={(app) => app.key}
                  onChange={onChangeAppList}
                  value={appListQuery.data.rows.filter((app) =>
                    values.media_keys.includes(app.key)
                  )}
                  disabled={
                    reportQuery.isLoading ||
                    values.partner_id === NO_VALUE ||
                    partnerListQuery.isLoading ||
                    appListQuery.isLoading
                  }
                />
              </Box>

              {/* 서비스 아이디 */}
              <Box sx={{ width: 250 }}>
                <Select
                  label="Service ID"
                  value={values.service_id}
                  onChange={onChangeServiceId}
                  onBlur={handleBlur("service_id")}
                  required
                  disabled={serviceListQuery.isLoading}
                >
                  {serviceList.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.desc}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {/* 시작일 */}
              <Box sx={{ width: 200 }}>
                <DateField
                  required
                  label="시작일"
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  value={values.since}
                  onChange={handleChangeDates("since")}
                  InputProps={{
                    onBlur: handleBlur("since"),
                    error: shouldErrorShows("since", touched, errors),
                  }}
                  disabled={reportQuery.isLoading}
                />
              </Box>

              {/* 종료일 */}
              <Box sx={{ width: 200 }}>
                <DateField
                  required
                  label="종료일"
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  value={values.until}
                  onChange={handleChangeDates("until")}
                  InputProps={{
                    onBlur: handleBlur("until"),
                    error: shouldErrorShows("until", touched, errors),
                  }}
                  disabled={reportQuery.isFetching}
                />
              </Box>

              {/* 조회 버튼 */}
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || reportQuery.isFetching}
              >
                조회하기
              </Button>
            </Stack>
          </Stack>

          {/* 리포트 테이블 */}
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
              <Typography variant="body2">
                * 전일, 전전일 데이터에 대해서는 재집계 될 수 있습니다.
              </Typography>
              <Button variant="outlined" onClick={onExport} disabled={!canExport}>
                엑셀 다운로드
              </Button>
            </Stack>
            <BasicTable
              onGridReady={(e) => setGridApi(e.api)}
              getRowId={(params) => getRowId(params.data)}
              animateRows
              rowData={reportQuery.data}
              columnDefs={getColumnDef(serviceList)}
              defaultColDef={{ sortable: false }}
            />
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
}
