import { FormEvent, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
import { Container, Typography, Paper, Button, Grid } from "@mui/material";

import { TextField } from "src/components/commons";
import usePopContentsCsDetail from "src/hooks/apis/popcontents/use-popcontents-cs-detail";
import useToast from "src/hooks/useToast";
import { PopContentsCsStatus } from "src/hooks/apis/popcontents/use-popcontents-cs-list";
import useReplyPopContentsCs from "src/hooks/apis/popcontents/use-reply-popcontents-cs";

const responseFormStyle = css`
  .response {
    margin-top: 1rem;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
  }
`;

export default function ResponseForm() {
  const { no } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [value, setValue] = useState("");

  const query = usePopContentsCsDetail(
    { no: no as string },
    {
      onError: (error) => {
        if (error.response?.status === 404) {
          navigate("/404", { replace: true });
        }
      },
      onSuccess: (response) => {
        const { data } = response.data;

        if (data) {
          setValue(data.response_content || "");
        }
      },
    }
  );

  const { mutate: replyCs, isLoading: isUpdating } = useReplyPopContentsCs();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const trimmedValue = value.trim();

    if (trimmedValue.length <= 2) {
      toast.error("2글자 이상 작성해주세요.");
      return;
    }

    if (query.data?.status === PopContentsCsStatus.Completed) {
      toast.error("이미 답변 완료된 문의입니다.");
      return;
    }

    replyCs(
      { no: no as string, response_content: value },
      {
        onSuccess: async () => {
          await query.refetch();
          toast.success("저장되었습니다.");
        },
      }
    );
  };

  return (
    <Container component="section" className="ssp-section" css={responseFormStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의 내용 및 답변
      </Typography>
      <Paper className="content" elevation={2}>
        <form id="service-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="field"
                label="문의 내용"
                value={query.data ? query.data.inquiry_content : ""}
                required
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={12} className="response">
              <TextField
                multiline
                minRows={8}
                fullWidth
                className="field"
                label="답변"
                placeholder="답변을 입력해 주세요."
                required
                readOnly={query.data?.status === PopContentsCsStatus.Completed}
                disabled={query.data?.status === PopContentsCsStatus.Completed}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Grid>
            {query.data?.status === PopContentsCsStatus.InProgress && (
              <Grid item xs={12} className="submit">
                <Button
                  type="submit"
                  disabled={isUpdating || !value.trim() || value.trim().length < 2}
                >
                  전송
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
