import { AxiosError, AxiosResponse } from "axios";
import { parseCookies } from "nookies";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import API from "src/utils/api";

import useApiError from "../useApiError";
import { PopContentsApp } from "./use-popcontents-app-list";

export type UpdatePopContentsAppPayload = Pick<
  PopContentsApp,
  "partner_id" | "key" | "channel_id" | "commission_rate" | "endpoint"
>;

type UpdatePopContentsAppMutationKey = "operation/popcontents/update-app";

type UpdatePopContentsAppMutationFn = MutationFunction<AxiosResponse, UpdatePopContentsAppPayload>;

type UpdatePopContentsAppMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, UpdatePopContentsAppPayload>,
  "mutationKey" | "mutationFn"
>;

export default function useUpdatePopContentsApp(options?: UpdatePopContentsAppMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<UpdatePopContentsAppMutationKey>(
    () => "operation/popcontents/update-app",
    []
  );

  const mutateFn = useCallback<UpdatePopContentsAppMutationFn>((values) => {
    const cookie = parseCookies();
    const { partner_id, key, ...payload } = values;

    return API.consoleV3.put(`/v3/popcontents/partners/${partner_id}/media/${key}`, payload, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
