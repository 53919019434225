import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type PopContentsReportRow = {
  campaign_complete_users: number;
  campaign_complete_views: number;
  campaign_play_users: number;
  campaign_play_views: number;
  media_cost: number;
  media_name: string;
  page_users: number;
  page_views: number;
  partner_name: string;
  service_name: string;

  // 아래 필드로 테이블의 unique key를 생성
  partner_id: number;
  media_key: string;
  report_date: string;
  channel_id: number;
  service_id: number;
  page_id: string;
};

export type PopContentsReportParams = {
  since: string;
  until: string;
  partner_id: number;
  company_key?: string; // 모든 매체 조회 시 회사키를 대신 전달(매체 키 목록이 너무 길어질 경우 대비)
  media_keys?: string[];
  service_id?: number;
};

type PopContentsReportData = {
  code: number;
  text: string;
  data: PopContentsReportRow[];
};

type PopContentsReportQueryKey = ["report/popcontents", PopContentsReportParams];

type PopContentsReportQueryFn = QueryFunction<
  AxiosResponse<PopContentsReportData>,
  PopContentsReportQueryKey
>;

type PopContentsReportQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsReportData>,
    AxiosError,
    AxiosResponse<PopContentsReportData>,
    PopContentsReportQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsReportData = {
  code: 200,
  text: "OK",
  data: [],
};

export default function usePopContentsReport(
  params: PopContentsReportParams,
  options?: PopContentsReportQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsReportQueryKey>(
    () => ["report/popcontents", params],
    [params]
  );

  const queryFn = useCallback<PopContentsReportQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/reports/popcontents", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params: {
        ...params,
        media_keys: params.company_key ? [] : params.media_keys,
      },
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
