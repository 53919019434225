import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BiRefresh } from "react-icons/bi";
import { BasicTable, DateField, TextField } from "src/components/commons";
import useCancelNpayTransactions from "src/hooks/apis/npay/use-cancel-npay-transactions";
import useNpayAppList, { NpayApp } from "src/hooks/apis/npay/use-npay-app-list";
import useNpayTransactionList, {
  NpayTransaction,
  NpayTransactionFields,
  NpayTransactionListParams,
  NpayTransactionStatus,
} from "src/hooks/apis/npay/use-npay-transactions";
import useOpenModal from "src/hooks/useOpenModal";
import useToast from "src/hooks/useToast";
import { APP_PLATFORM_ALIAS, AppPlatform, Campaign, CAMPAIGN_ALIAS, STATUS } from "src/types";
import { shouldErrorShows } from "src/utils/form-helper";
import { mixed, object, string } from "yup";
import { CS_MAX_DATE, MIN_DATE, SEARCH_PARAMS_VALIDATOR } from "../const";
import { useLocation, useNavigate } from "react-router-dom";
import { parse, stringify } from "qs";

const searchParamsValidationSchema = object({
  media_key: string().required(),
  usn: string().required(),
  placement_id: string(),
  since: string().test("isValidSince", SEARCH_PARAMS_VALIDATOR.since(CS_MAX_DATE)),
  until: string().test("isValidUntil", SEARCH_PARAMS_VALIDATOR.until(CS_MAX_DATE)),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
});

const defaultSearchParams: NpayTransactionListParams = {
  media_key: "",
  usn: "",
  placement_id: "",
  since: moment().subtract(6, "d").format("YYYYMMDD"),
  until: moment().format("YYYYMMDD"),
  page_no: 1,
  page_size: 20,
  orders: ["-created_at"],
};

type FormValues = Override<
  Pick<NpayTransactionListParams, "media_key" | "usn" | "placement_id" | "since" | "until">,
  {
    since: moment.Moment | null;
    until: moment.Moment | null;
  }
>;

const formValidationSchema = object({
  media_key: string().required(),
  usn: string().required(),
  placement_id: string(),
  since: mixed().required(),
  until: mixed().required(),
});

const columnDef: ColDef[] = [
  {
    headerName: "날짜",
    field: "created_at",
    sort: "desc",
    lockPosition: "left",
    pinned: true,
    checkboxSelection: (params) => {
      const { status } = params.data;
      return status === NpayTransactionStatus.Completed;
    },
    sortable: true,
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    valueFormatter: (params) => {
      return NpayTransactionStatus[params.value];
    },
  },
  {
    headerName: "미디어키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 ID",
    field: "placement_id",
    sortable: true,
  },
  {
    headerName: "광고 타입",
    field: "ad_type",
    sortable: true,
    valueFormatter: (params) => {
      return CAMPAIGN_ALIAS[params.value as Campaign];
    },
  },
  {
    headerName: "리워드 단위",
    field: "point",
    sortable: true,
  },
  {
    headerName: "네이버 ID",
    field: "naver_id",
    sortable: true,
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
  },
  {
    headerName: "ADID/IDFA",
    field: "identifier",
    sortable: true,
  },
  {
    headerName: "제휴사 거래번호",
    field: "ssp_tx_no",
    sortable: true,
  },
  {
    headerName: "다우 거래번호",
    field: "daou_tx_no",
    sortable: true,
  },
  {
    headerName: "플랫폼",
    field: "platform",
    sortable: true,
    valueFormatter: (params) => {
      return APP_PLATFORM_ALIAS[params.value as AppPlatform];
    },
  },
];

export default function Transactions() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi>();

  // 거래 내역 목록 query params
  const [transActionsParams, setTransActionsParams] = useState<NpayTransactionListParams>(() => {
    const parsedSearch = parse(location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        media_key: validatedParams.media_key,
        usn: validatedParams.usn,
        placement_id: validatedParams.placement_id || "",
        since: moment(validatedParams.since).format("YYYYMMDD"),
        until: moment(validatedParams.until).format("YYYYMMDD"),
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  // 거래 내역 목록 query 조회 여부
  const [enableQuery, setEnableQuery] = useState(() => {
    return searchParamsValidationSchema.isValidSync(transActionsParams);
  });

  // 이전 거래 내역 목록 query params
  const [prevTransActionsParams, setPrevTransActionsParams] =
    useState<NpayTransactionListParams | null>(null);

  const initialFormValues = useMemo<FormValues>(() => {
    return {
      media_key: transActionsParams.media_key,
      usn: transActionsParams.usn,
      placement_id: transActionsParams.placement_id,
      since: moment(transActionsParams.since).startOf("d"),
      until: moment(transActionsParams.until).startOf("d"),
    };
  }, [transActionsParams]);

  // 조회하기 버튼 핸들러
  const handleSubmitForm = (values: FormValues) => {
    setTransActionsParams((prev) => ({
      ...prev,
      ...values,
      since: values.since?.format("YYYYMMDD") || "",
      until: values.until?.format("YYYYMMDD") || "",
      page_no: 1,
    }));

    setEnableQuery(true);
  };

  // formik
  const {
    setValues,
    setFieldValue,
    values: formValues,
    isValid,
    errors,
    touched,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: formValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  // 거래 내역 목록 조회 query
  const npayTransactionsListQuery = useNpayTransactionList(transActionsParams, {
    enabled: enableQuery,
    onSuccess: () => {
      setPrevTransActionsParams(transActionsParams);
      setEnableQuery(false);
    },
  });

  // 앱 매체 목록 query (select 용도)
  const npayAppListQuery = useNpayAppList(
    {
      page_no: 1,
      page_size: 10000,
      status: STATUS.ACTIVE,
    },
    {
      onSuccess: (response) => {
        const { rows } = response.data.data;

        setTransActionsParams((prev) => {
          const allMediaKeys = rows.map((app) => app.key);

          return {
            ...prev,
            media_key: allMediaKeys.includes(prev.media_key) ? prev.media_key : "",
          };
        });
      },
    }
  );

  useEffect(() => {
    const searchParams = stringify(transActionsParams, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/npay/transactions" + searchParams, {
      replace: true,
    });
  }, [navigate, transActionsParams]);

  // 거래 취소 mutation
  const { mutate: cancelTransactions, isLoading: isCanceling } = useCancelNpayTransactions();

  // 체크된 거래 내역
  const [selectedTransActions, setSelectedTransactions] = useState<NpayTransaction[]>([]);

  const handleSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows() as NpayTransaction[];
    setSelectedTransactions(selectedRows);
  }, []);

  // 거래 취소 확인 모달 상태
  const [openConfirmationModal, onShowConfirmationModal, onCloseConfirmationModal] =
    useOpenModal(null);

  // 거래 취소 버튼 핸들러
  const handleCancelTransactions = useCallback(() => {
    cancelTransactions(
      {
        ad_request_no: selectedTransActions.map(({ ad_request_no }) => ad_request_no),
      },
      {
        onSuccess: async () => {
          toast.success("거래 취소가 완료되었습니다.");
          await npayTransactionsListQuery.refetch();
          onCloseConfirmationModal();
        },
      }
    );
  }, [
    cancelTransactions,
    npayTransactionsListQuery,
    onCloseConfirmationModal,
    selectedTransActions,
    toast,
  ]);

  const handleChangePage = useCallback((page: number) => {
    setTransActionsParams((prev) => ({ ...prev, page_no: page }));
    setEnableQuery(true);
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setTransActionsParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
    setEnableQuery(true);
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId)) as NpayTransactionFields[];

    setTransActionsParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
    setEnableQuery(true);
  }, []);

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(CS_MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            ...prev,
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            ...prev,
            since: value,
            until: newEndDate.isAfter(CS_MAX_DATE) ? CS_MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const handleChangeApp = useCallback(
    (_: SyntheticEvent<unknown>, v: NpayApp | null) => {
      setFieldValue("media_key", v ? v.key : "");
    },
    [setFieldValue]
  );

  const handleChangeText = useCallback(
    (field: "usn" | "placement_id") => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleRefetchTransactionsList = useCallback(async () => {
    await npayTransactionsListQuery.refetch();
  }, [npayTransactionsListQuery]);

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  const handleExportExcel = useCallback(() => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        columnGroups: true,
        fileName: `네이버페이관리_거래내역(${transActionsParams.since}-${transActionsParams.until}).xlsx`,
      });
    }
  }, [gridApi, transActionsParams.since, transActionsParams.until]);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: transActionsParams.page_no as number,
        count: npayTransactionsListQuery.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: transActionsParams.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [
      handleChangePage,
      handleChangePageSize,
      npayTransactionsListQuery.data.pages,
      transActionsParams.page_no,
      transActionsParams.page_size,
    ]
  );

  const selectedApp =
    npayAppListQuery.data.rows.find((app) => app.key === formValues.media_key) || null;

  const hasRow = npayTransactionsListQuery.data.rows.length > 0;
  const canCancel = selectedTransActions.length > 0;

  return (
    <Box sx={{ mt: "2rem" }}>
      <Stack component="form" onSubmit={handleSubmit} mb="1rem" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{ width: 300 }}>
            <DateField
              label="시작일"
              minDate={MIN_DATE}
              maxDate={CS_MAX_DATE}
              value={formValues.since}
              onChange={handleChangeDates("since")}
              InputProps={{
                onBlur: handleBlur("since"),
                error: shouldErrorShows("since", touched, errors),
              }}
              disabled={npayTransactionsListQuery.isFetching}
            />
          </Box>
          <Box sx={{ width: 300 }}>
            <DateField
              label="종료일"
              minDate={MIN_DATE}
              maxDate={CS_MAX_DATE}
              value={formValues.until}
              onChange={handleChangeDates("until")}
              InputProps={{
                onBlur: handleBlur("until"),
                error: shouldErrorShows("until", touched, errors),
              }}
              disabled={npayTransactionsListQuery.isFetching}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Autocomplete
            sx={{ width: 300, flexShrink: 0 }}
            options={npayAppListQuery.data.rows}
            getOptionLabel={(app) => `${app.name} (${app.key})`}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="매체 명"
                placeholder="앱 매체를 선택하세요."
                required
                error={shouldErrorShows("media_key", touched, errors)}
              />
            )}
            value={selectedApp}
            onChange={handleChangeApp}
            onBlur={handleBlur("media_key")}
            disabled={npayTransactionsListQuery.isFetching}
          />
          <TextField
            sx={{ width: 300, flexShrink: 0 }}
            required
            label="USN"
            placeholder="USN을 입력해 주세요."
            value={formValues.usn}
            onChange={handleChangeText("usn")}
            onBlur={handleBlur("usn")}
            error={shouldErrorShows("usn", touched, errors)}
            disabled={npayTransactionsListQuery.isFetching}
          />
          <TextField
            sx={{ width: 300, flexShrink: 0 }}
            label="플레이스먼트 ID"
            placeholder="플레이스먼트 ID를 입력해 주세요."
            value={formValues.placement_id}
            onChange={handleChangeText("placement_id")}
            onBlur={handleBlur("placement_id")}
            error={shouldErrorShows("placement_id", touched, errors)}
            disabled={npayTransactionsListQuery.isFetching}
          />
          <Stack direction="row" alignItems="center" alignSelf="end" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              disabled={!isValid || npayTransactionsListQuery.isFetching}
            >
              조회하기
            </Button>
            <IconButton
              type="button"
              color="primary"
              onClick={handleRefetchTransactionsList}
              disabled={prevTransActionsParams === null || npayTransactionsListQuery.isFetching} // 이전 query params가 없으면
            >
              <BiRefresh />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* TODO: URL 적용 */}
            <Button href="#" target="_blank">
              다우기술 페이지 바로가기
            </Button>
            <Button
              variant="outlined"
              type="button"
              disabled={!hasRow || npayTransactionsListQuery.isFetching}
              onClick={handleExportExcel}
            >
              엑셀 다운로드
            </Button>
          </Stack>
          <Button
            variant="contained"
            type="button"
            onClick={onShowConfirmationModal}
            disabled={!canCancel}
          >
            거래 취소
          </Button>
        </Stack>
        <BasicTable
          getRowId={(params) => params.data.ad_request_no}
          animateRows
          rowData={npayTransactionsListQuery.data.rows}
          columnDefs={columnDef}
          {...paginationInfo}
          onSortChanged={handleSortChange}
          onGridReady={handleGridReady}
          rowSelection="multiple"
          onSelectionChanged={handleSelectionChanged}
        />
      </Stack>
      {openConfirmationModal.isOpen && (
        <Dialog fullWidth open onClose={onCloseConfirmationModal}>
          <DialogTitle id="cancel-npay-transactions-modal-title">거래 취소</DialogTitle>
          <DialogContent className="cancel-npay-transactions-modal-dialog-content">
            <Stack pt="20px" spacing={1}>
              <Typography variant="body1">선택하신 거래에 대해서 취소처리 하시겠습니까?</Typography>
              <Typography variant="body1">취소 시 지급된 포인트는 마이너스 처리 됩니다.</Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ marginBottom: "1rem" }}>
            <Button
              type="button"
              onClick={onCloseConfirmationModal}
              color="inherit"
              disabled={isCanceling}
            >
              취소
            </Button>
            <Button
              type="button"
              onClick={handleCancelTransactions}
              disabled={!canCancel || isCanceling}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
