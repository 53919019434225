import { FormikErrors, FormikTouched } from "formik";

export const createFormData = (data: Record<string, unknown>) => {
  const formData = new FormData();
  for (const i in data) {
    if (typeof data[i] === "number") data[i] = `${data[i]}`;
    if (data[i]) {
      formData.set(i, data[i] as string | Blob);
    }
  }
  return formData;
};

export const shouldErrorShows = <V extends Record<string, unknown>>(
  fieldName: keyof V,
  touched: FormikTouched<V>,
  errors: FormikErrors<V>
) => {
  return Boolean(touched[fieldName] && errors[fieldName]);
};

export const getHelperText = <V extends Record<string, unknown>>(
  fieldName: keyof V,
  touched: FormikTouched<V>,
  errors: FormikErrors<V>,
  tip?: string
) => {
  return shouldErrorShows(fieldName, touched, errors) ? errors[fieldName] : tip;
};

export const REG_EXP = {
  /** 이메일 정규표현식 */
  email:
    // eslint-disable-next-line no-control-regex
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  /** 인증번호 정규표현식 */
  verifyingCode: /^[0-9]{7}$/,
  /** 비밀번호 정규표현식 */
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  /** 휴대전화 정규표현식 */
  phone: /^(0?((1[0|1|6|7|8|9]{1})|2|([3|4|5|6]([1|6|7|8|9]{1}))))-{0,1}(\d{3,4})-{0,1}(\d{4})$/,
  /** 주민번호 정규표현식 */
  rrn: /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-{0,1}([1-4][0-9]{6})$/,
  /** 테스트 디바이스 정규표현식 */
  testDevice:
    /^([0-9A-Fa-f]{8})-{0,1}([0-9A-Fa-f]{4})-{0,1}(4[0-9A-Fa-f]{3})-{0,1}([0-9A-Fa-f]{4})-{0,1}([0-9A-Fa-f]{12})$/,
  /** 사업자등록번호 정규표현식 */
  brn: {
    [Symbol.match](brn: string | number): RegExpMatchArray | null {
      brn = `${brn}`.replace(/-/g, "");
      const result = brn.match(/\d{1}/g);
      if (result === null || result.length !== 10) return null;
      const key = [1, 3, 7, 1, 3, 7, 1, 3, 5];
      const sum = key.reduce((acc, cur, i) => {
        acc += cur * Number(result[i]);
        return acc;
      }, 0);

      const chkSum = Math.floor((key[8] * Number(result[8])) / 10);
      if ((10 - ((sum + chkSum) % 10)) % 10 === Number(result[9])) {
        return result;
      }
      return null;
    },
  },
  /** Package 이름 정규표현식 */
  packageName: /(^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z\d_.]*$)|(^id\d+$)|(^[0-9]+$)/,
  /** 숫자 정규표현식 */
  number: /(^[0-9]+$)/,
  word: /^[a-zA-Z0-9]*$/,
  urlScheme: /[a-zA-Z0-9]+:\/\/$/,
  bidfloor: /^\d*[.]\d{3}$/,
  /** 도메인 정규표현식 */
  domain:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  name: /^[가-힣a-zA-Z\s]+$/,
  subId: /^[A-Za-z0-9]{8,20}$/,
  commissionRate: /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/,
  postbackUrl: /^https?:\/\//,
  numberInput: /^([1-9]\d*(\.)\d{1,2}|0(\.)\d{1,2}|[1-9]\d*|0|0(\.)|[1-9]\d*(\.))$/,
};

export const numberFormatter = (value: number | string, point = 2): string => {
  if (Number.isNaN(Number.parseFloat(value as string))) {
    return "";
  }
  let str = value.toString();
  const dotCurr = str.lastIndexOf(".");
  const paramPoint = point;
  if (dotCurr === -1) {
    point = 0;
    if (paramPoint > 0) str = str.concat(".");
  } else {
    point = Math.min(str.length - dotCurr - 1, point);
  }

  return str.replace(
    new RegExp(`(\\d)(?=(?:\\d{3})+(?:\\.|$))|(\\.${"\\d".repeat(point)})\\d*$`, "g"),
    (_: string, s1: string, s2: string) => {
      return s2 ? (paramPoint > 0 ? s2 + "0".repeat(paramPoint - point) : "") : s1 + ",";
    }
  );
};

export const phoneFormatter = (value: number | string): string => {
  const type = typeof value;
  if (type !== "string" && type !== "number") {
    return "";
  }
  if (type === "string") {
    return (value as string).replace(REG_EXP.phone, (_0, g1, _1, _2, _3, _4, g6, g7) => {
      return `${g1}-${g6}-${g7}`;
    });
  }
  return value.toString();
};
