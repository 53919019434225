import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import { AxiosError, AxiosResponse } from "axios";
import { parseCookies } from "nookies";

import API from "src/utils/api";

import useApiError from "../useApiError";
import { PopContentsPlcmt } from "./use-popcontents-plcmt-list";

export type UpdatePopContentsPlcmtPayload = Pick<
  PopContentsPlcmt,
  | "partner_id"
  | "media_key"
  | "id"
  | "service_id"
  | "page_id"
  | "landing_url"
  | "ssp_plcmt_ids"
  | "campaign_ids"
>;

type UpdatePopContentsPlcmtMutationKey = "operation/popcontents/update-plcmt";

type UpdatePopContentsPlcmtMutationFn = MutationFunction<
  AxiosResponse,
  UpdatePopContentsPlcmtPayload
>;

type UpdatePopContentsPlcmtMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, UpdatePopContentsPlcmtPayload>,
  "mutationKey" | "mutationFn"
>;

export default function useUpdatePopContentsPlcmt(options?: UpdatePopContentsPlcmtMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<UpdatePopContentsPlcmtMutationKey>(
    () => "operation/popcontents/update-plcmt",
    []
  );

  const mutateFn = useCallback<UpdatePopContentsPlcmtMutationFn>((values) => {
    const cookie = parseCookies();
    const { partner_id, media_key, id, ...payload } = values;

    return API.consoleV3.put(
      `/v3/popcontents/partners/${partner_id}/media/${media_key}/placements/${id}`,
      payload,
      {
        headers: {
          "x-api-type": "1",
          Authorization: `Bearer ${cookie.access_token || ""}`,
        },
      }
    );
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
