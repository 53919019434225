import { Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const PopContentsLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChangeValue = useCallback(
    (_: SyntheticEvent, value: string) => {
      navigate(value);
    },
    [navigate]
  );

  return (
    <Container component="section" className="ssp-section" maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        팝콘텐츠 관리
      </Typography>
      <Paper className="content" elevation={2}>
        <Tabs sx={{ mb: "2rem" }} value={pathname} onChange={handleChangeValue}>
          <Tab label="팝콘텐츠 앱 관리" value="/operation/popcontents/apps" />
          <Tab label="팝콘텐츠 지면 관리" value="/operation/popcontents/placements" />
          <Tab label="CS 관리" value="/operation/popcontents/cs" />
          <Tab label="외주사 관리" value="/operation/popcontents/partners" />
        </Tabs>
        <Outlet />
      </Paper>
    </Container>
  );
};

export default PopContentsLayout;
