import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from "react";
import { number, object, string } from "yup";

import usePopContentsPartnerList, {
  PopContentsPartner,
} from "src/hooks/apis/popcontents/use-popcontents-partner-list";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import useGetAppMediaList, { AppMedia } from "src/hooks/apis/media/useGetAppMediaList";
import useCreatePopContentsApp from "src/hooks/apis/popcontents/use-create-popcontents-app";
import { Select, TextField } from "src/components/commons";
import useToast from "src/hooks/useToast";
import { APP_PLATFORM_ALIAS, COMPANY, STATUS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";

const NO_VALUE = -1;

type CreatePopContentsAppFormValues = {
  company_key: string;
  key: string;
  partner_id: number;
  channel_id: number;
  commission_rate: number;
  url: string;
  method: string;
};

const createAppFormSchema = object({
  company_key: string().required("업체를 선택해 주세요."),
  key: string().required("앱 매체를 선택해 주세요."),
  partner_id: number()
    .required("외주사를 선택해 주세요.")
    .notOneOf([NO_VALUE], "외주사를 선택해 주세요."),
  channel_id: number()
    .required("외주사에서 발급한 channel ID를 입력해 주세요.")
    .notOneOf([NO_VALUE], "외주사에서 발급한 channel ID를 입력해 주세요."),
  commission_rate: number()
    .required("수수료율을 입력해 주세요.")
    .notOneOf([NO_VALUE], "수수료율을 입력해 주세요."),
  method: string().required().oneOf(["GET", "POST"]),
  url: string()
    .matches(REG_EXP.domain, "URL 형식을 확인해 주세요.")
    .required("매체사에 요청할 end point URL을 입력해주세요."),
});

export default function CreateAppModal(props: {
  onCreate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { onCreate, onClose } = props;

  const toast = useToast();

  const { mutate: createApp, isLoading: isCreating } = useCreatePopContentsApp();

  const initialValues = useMemo<CreatePopContentsAppFormValues>(
    () => ({
      company_key: "",
      key: "",
      partner_id: NO_VALUE,
      channel_id: NO_VALUE,
      commission_rate: 0,
      method: "POST",
      url: "",
    }),
    []
  );

  const [commissionRateStr, setCommissionRateStr] = useState(String(initialValues.commission_rate));

  const handleCreate = ({ method, url, ...values }: CreatePopContentsAppFormValues) => {
    createApp(
      {
        endpoint: { method, url },
        ...values,
      },
      {
        onSuccess: () => {
          toast.success("저장되었습니다.");
          onClose();
          onCreate();
        },
      }
    );
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isValid,
    setValues,
    getFieldProps,
  } = useFormik({
    initialValues,
    onSubmit: handleCreate,
    validationSchema: createAppFormSchema,
  });

  // 회사 목록 조회
  const companyListQuery = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    status: STATUS.ACTIVE,
    pageNo: 1,
    pageSize: 10000,
    orders: ["name"],
  });

  // 앱 매체 목록 조회 (empty string이면 조회 X)
  const appMediaListQuery = useGetAppMediaList({
    companyKey: values.company_key,
    disabled: !values.company_key,
  });

  // 외주사 목록 조회
  const partnerListQuery = usePopContentsPartnerList({
    page_no: 1,
    page_size: 10000,
    orders: ["name"],
  });

  const onChangeCompany = useCallback(
    (_: SyntheticEvent<unknown>, v: CompanyData | null) => {
      setValues((prev) => ({
        ...prev,
        company_key: v?.key || "",
        key: "",
      }));
    },
    [setValues]
  );

  const onChangeApp = useCallback(
    (_: SyntheticEvent<unknown>, v: AppMedia | null) => {
      setFieldValue("key", v?.key || "");
    },
    [setFieldValue]
  );

  const onChangePartner = useCallback(
    (_: SyntheticEvent<unknown>, v: PopContentsPartner | null) => {
      setFieldValue("partner_id", v?.id || "");
    },
    [setFieldValue]
  );

  const onChangeChannelId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "" || REG_EXP.number.test(value)) {
        setFieldValue("channel_id", value === "" ? NO_VALUE : parseInt(value));
      }
    },
    [setFieldValue]
  );

  const onChangeCommissionRate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "" || REG_EXP.numberInput.test(value)) {
        setCommissionRateStr(value);
        setFieldValue("commission_rate", value === "" ? NO_VALUE : parseFloat(value));
      }
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="create-popcontents-app-modal-title">팝콘텐츠 앱 매체 등록</DialogTitle>
      <DialogContent className="create-popcontents-app-modal-dialog-content">
        <Stack
          component="form"
          id="create-popcontents-app-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <Autocomplete
            options={companyListQuery.data.companies}
            getOptionLabel={(company) => company.name}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="업체 명"
                placeholder="업체를 선택해주세요."
                required
                error={shouldErrorShows("company_key", touched, errors)}
                helperText={getHelperText("company_key", touched, errors)}
              />
            )}
            value={
              companyListQuery.data.companies.find(
                (company) => company.key === values.company_key
              ) || null
            }
            onChange={onChangeCompany}
            onBlur={handleBlur("company_key")}
            disabled={companyListQuery.isLoading || isCreating}
          />

          {/* 앱 매체 명 */}
          <Autocomplete
            options={appMediaListQuery.data.media}
            getOptionLabel={(media) => `[${APP_PLATFORM_ALIAS[media.platform_type]}] ${media.name}`}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="앱 매체 명"
                placeholder="업체 선택 후 앱 매체를 선택해 주세요."
                required
                error={shouldErrorShows("key", touched, errors)}
                helperText={getHelperText("key", touched, errors)}
              />
            )}
            value={appMediaListQuery.data.media.find((media) => media.key === values.key) || null}
            onChange={onChangeApp}
            onBlur={handleBlur("key")}
            disabled={!values.company_key || appMediaListQuery.isLoading || isCreating}
          />

          {/* 매체 키 */}
          <TextField
            required
            label="매체 키"
            placeholder="업체 선택 후 앱 매체를 선택해 주세요."
            value={values.key}
            aria-readonly
            disabled
          />

          {/* 외주사 명 */}
          <Autocomplete
            options={partnerListQuery.data.rows}
            getOptionLabel={(partner) => partner.name}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="외주사 명"
                placeholder="외주사를 선택해 주세요."
                required
                error={shouldErrorShows("partner_id", touched, errors)}
                helperText={getHelperText("partner_id", touched, errors)}
              />
            )}
            value={
              partnerListQuery.data.rows.find((partner) => partner.id === values.partner_id) || null
            }
            onChange={onChangePartner}
            onBlur={handleBlur("partner_id")}
            disabled={partnerListQuery.isLoading || isCreating}
          />

          {/* 채널 ID */}
          <TextField
            required
            label="Channel ID"
            placeholder="외주사에서 발급한 channel ID를 입력해 주세요."
            value={values.channel_id === NO_VALUE ? "" : String(values.channel_id)}
            onChange={onChangeChannelId}
            onBlur={handleBlur("channel_id")}
            error={shouldErrorShows("channel_id", touched, errors)}
            helperText={getHelperText("channel_id", touched, errors)}
            disabled={isCreating}
          />

          {/* 대행 수수료율 */}
          <TextField
            label="대행 수수료율"
            required
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  %
                </Typography>
              ),
            }}
            placeholder="수수료율을 입력해 주세요."
            value={commissionRateStr}
            onChange={onChangeCommissionRate}
            onBlur={handleBlur("commission_rate")}
            error={shouldErrorShows("commission_rate", touched, errors)}
            helperText={
              getHelperText("commission_rate", touched, errors) ||
              "매체사와 애드팝콘간의 수수료율을 의미합니다."
            }
            disabled={isCreating}
          />

          <Stack direction="row" alignItems="start" spacing={3}>
            {/* method */}
            <Select
              label="method"
              {...getFieldProps("method")}
              required
              fullWidth={false}
              sx={{ width: 90, flexShrink: 0 }}
              disabled={isCreating}
            >
              {["GET", "POST"].map((method) => (
                <MenuItem key={method} value={method}>
                  {method}
                </MenuItem>
              ))}
            </Select>

            {/* endpoint */}
            <TextField
              sx={{ flexGrow: 1 }}
              required
              label="endpoint"
              placeholder="매체사에 요청할 end point URL을 입력해 주세요."
              {...getFieldProps("url")}
              error={shouldErrorShows("url", touched, errors)}
              helperText={getHelperText("url", touched, errors)}
              disabled={isCreating}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit" disabled={isCreating}>
          취소
        </Button>
        <Button type="submit" form="create-popcontents-app-modal" disabled={!isValid || isCreating}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
