import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import { AxiosError, AxiosResponse } from "axios";

import API from "src/utils/api";

import useApiError from "../useApiError";

export type PopContentsService = {
  id: number;
  desc: string;
};

type PopContentsServiceListData = {
  code: number;
  text: string;
  data: PopContentsService[];
};

type PopContentsServiceListQueryKey = "operation/popcontents/service-list";

type PopContentsServiceListQueryFn = QueryFunction<
  AxiosResponse<PopContentsServiceListData>,
  PopContentsServiceListQueryKey
>;

type PopContentsServiceListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsServiceListData>,
    AxiosError,
    AxiosResponse<PopContentsServiceListData>,
    PopContentsServiceListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsServiceListData = {
  code: 200,
  text: "OK",
  data: [],
};

export default function usePopContentsServiceList(options?: PopContentsServiceListQueryOptions) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsServiceListQueryKey>(
    () => "operation/popcontents/service-list",
    []
  );

  const queryFn = useCallback<PopContentsServiceListQueryFn>(() => {
    return API.consoleV3.get("/v3/info/popcontent-service");
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
