import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";

interface DateFieldProps extends Omit<DatePickerProps<Moment, Moment>, "renderInput"> {
  size?: "medium" | "small";
  fullWidth?: boolean;
  shrink?: boolean;
  required?: boolean;
}

const DateField = ({
  size = "small",
  fullWidth = true,
  shrink = true,
  ...props
}: DateFieldProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        renderInput={(params) => (
          <TextField
            InputLabelProps={{
              shrink: shrink,
            }}
            fullWidth={fullWidth}
            size={size}
            required={props.required}
            {...params}
          />
        )}
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DateField;
