import { Box, Button, Stack } from "@mui/material";
import { ColDef, GridApi, GridReadyEvent, SortChangedEvent } from "ag-grid-community";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BasicTable, DateField } from "src/components/commons";
import useFailedNpayTransactionList, {
  FailedNpayTransactionFields,
  FailedNpayTransactionListParams,
} from "src/hooks/apis/npay/use-failed-npay-transactions";
import useToast from "src/hooks/useToast";
import { MAX_DATE, MIN_DATE, SEARCH_PARAMS_VALIDATOR } from "../const";
import { useLocation, useNavigate } from "react-router-dom";
import { parse, stringify } from "qs";
import { CAMPAIGN_ALIAS, Campaign, APP_PLATFORM_ALIAS, AppPlatform } from "src/types";
import { object, string, mixed } from "yup";

const searchParamsValidationSchema = object({
  since: string().test("isValidSince", SEARCH_PARAMS_VALIDATOR.since(MAX_DATE)),
  until: string().test("isValidUntil", SEARCH_PARAMS_VALIDATOR.until(MAX_DATE)),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidMediaKeys", SEARCH_PARAMS_VALIDATOR.orders),
});

const defaultSearchParams: FailedNpayTransactionListParams = {
  since: moment().subtract(7, "d").format("YYYYMMDD"),
  until: moment().subtract(1, "d").format("YYYYMMDD"),
  page_no: 1,
  page_size: 20,
  orders: ["-created_at"],
};

const columnDef: ColDef[] = [
  {
    headerName: "날짜",
    field: "created_at",
    sort: "desc",
    lockPosition: "left",
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "미디어키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 ID",
    field: "placement_id",
    sortable: true,
  },
  {
    headerName: "광고 타입",
    field: "ad_type",
    sortable: true,
    valueFormatter: (params) => {
      return CAMPAIGN_ALIAS[params.value as Campaign];
    },
  },
  {
    headerName: "리워드 단위",
    field: "point",
    sortable: true,
  },
  {
    headerName: "네이버 ID",
    field: "naver_id",
    sortable: true,
  },
  {
    headerName: "USN",
    field: "usn",
    sortable: true,
  },
  {
    headerName: "ADID/IDFA",
    field: "identifier",
    sortable: true,
  },
  {
    headerName: "제휴사 거래번호",
    field: "ssp_tx_no",
    sortable: true,
  },
  {
    headerName: "다우 거래번호",
    field: "daou_tx_no",
    sortable: true,
  },
  {
    headerName: "플랫폼",
    field: "platform",
    sortable: true,
    valueFormatter: (params) => {
      return APP_PLATFORM_ALIAS[params.value as AppPlatform];
    },
  },
];

export default function FailedTransactions() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi>();

  const [params, setParams] = useState<FailedNpayTransactionListParams>(() => {
    const parsedSearch = parse(location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        since: moment(validatedParams.since).format("YYYYMMDD"),
        until: moment(validatedParams.until).format("YYYYMMDD"),
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  const [dates, setDates] = useState<{ since: moment.Moment | null; until: moment.Moment | null }>({
    since: moment(params.since).startOf("d"),
    until: moment(params.until).startOf("d"),
  });

  // moment -> dates
  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      since: dates.since?.format("YYYYMMDD") || "",
      until: dates.until?.format("YYYYMMDD") || "",
    }));
  }, [dates]);

  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/npay/failed-transactions" + searchParams, {
      replace: true,
    });
  }, [navigate, params]);

  const query = useFailedNpayTransactionList(params);

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setDates((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            since: value,
            until: newEndDate.isAfter(MAX_DATE) ? MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [toast]
  );

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: query.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [handleChangePage, handleChangePageSize, query.data.pages, params.page_no, params.page_size]
  );

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) =>
        sort === "desc" ? `-${colId}` : colId
      ) as FailedNpayTransactionFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
  }, []);

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  const handleExportExcel = useCallback(() => {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        columnGroups: true,
        fileName: `네이버페이관리_거래_실패_내역_리스트(${params.since}-${params.until}).xlsx`,
      });
    }
  }, [gridApi, params]);

  const canExport = query.data.rows.length > 0 && !query.isFetching && Boolean(gridApi);

  return (
    <Stack mt="2rem" mb="1rem" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{ width: 300 }}>
            <DateField
              label="시작일"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              value={dates.since}
              onChange={handleChangeDates("since")}
              InputProps={{
                error: dates.since === null,
              }}
              disabled={query.isFetching}
            />
          </Box>
          <Box sx={{ width: 300 }}>
            <DateField
              label="종료일"
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              value={dates.until}
              onChange={handleChangeDates("until")}
              InputProps={{
                error: dates.until === null,
              }}
              disabled={query.isFetching}
            />
          </Box>
        </Stack>
        <Button variant="outlined" onClick={handleExportExcel} disabled={!canExport}>
          엑셀 다운로드
        </Button>
      </Stack>
      <BasicTable
        getRowId={(params) => params.data.ad_request_no}
        animateRows
        rowData={query.data.rows}
        columnDefs={columnDef}
        {...paginationInfo}
        onSortChanged={handleSortChange}
        onGridReady={handleGridReady}
      />
    </Stack>
  );
}
