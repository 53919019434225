import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type PopContentsPartner = {
  brn: string;
  created_at: string;
  key: string;
  name: string;
  commission_rate: number;
  id: number;
  plcmt_cnt: number;
  white_ips: string[];
};

export type PopContentsPartnerFields = keyof PopContentsPartner | `-${keyof PopContentsPartner}`;

export type PopContentsPartnerListParams = {
  query?: string;
  page_no?: number;
  page_size?: number;
  orders?: PopContentsPartnerFields[];
};

type PopContentsPartnerListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: PopContentsPartner[];
  };
};

type PopContentsPartnerListQueryKey = [
  "operation/popcontents/partner-list",
  PopContentsPartnerListParams
];

type PopContentsPartnerListQueryFn = QueryFunction<
  AxiosResponse<PopContentsPartnerListData>,
  PopContentsPartnerListQueryKey
>;

type PopContentsPartnerListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsPartnerListData>,
    AxiosError,
    AxiosResponse<PopContentsPartnerListData>,
    PopContentsPartnerListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsPartnerListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function usePopContentsPartnerList(
  params: PopContentsPartnerListParams,
  options?: PopContentsPartnerListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsPartnerListQueryKey>(
    () => ["operation/popcontents/partner-list", params],
    [params]
  );

  const queryFn = useCallback<PopContentsPartnerListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/popcontents/partners", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
