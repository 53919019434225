import { ChangeEvent, useCallback, useMemo, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { array, number, object, string } from "yup";

import { MultipleSelect, Select, TextField } from "src/components/commons";
import useGetAppPlacementList, {
  AppPlacement,
} from "src/hooks/apis/placements/useGetAppPlacementList";
import { PopContentsPlcmt } from "src/hooks/apis/popcontents/use-popcontents-plcmt-list";
import { PopContentsService } from "src/hooks/apis/popcontents/use-popcontents-service-list";
import useUpdatePopContentsPlcmt, {
  UpdatePopContentsPlcmtPayload,
} from "src/hooks/apis/popcontents/use-update-popcontents-plcmt";
import useToast from "src/hooks/useToast";
import { STATUS } from "src/types";
import { getHelperText, REG_EXP, shouldErrorShows } from "src/utils/form-helper";

type UpdatePopContentsPlcmtFormValues = UpdatePopContentsPlcmtPayload & {
  partner_name: string;
  media_name: string;
  channel_id: number;
};

const NO_VALUE = -1;

const updatePlcmtFormSchema = object({
  // readonly
  partner_id: number().required(),
  partner_name: string().required(),
  media_key: string().required(),
  media_name: string().required(),
  id: string().required(),
  channel_id: number().required(),

  // editable
  service_id: number()
    .required("서비스 ID를 선택해 주세요.")
    .notOneOf([NO_VALUE], "서비스 ID를 선택해 주세요."),
  page_id: string().required("page ID를 입력해 주세요."),
  landing_url: string()
    .matches(REG_EXP.domain, "URL 형식을 확인해 주세요.")
    .required("페이지의 URL을 입력해 주세요."),
  ssp_plcmt_ids: array().of(string()).min(1, "페이지에 연결된 플레이스먼트를 모두 선택해 주세요. "),
  campaign_ids: array().of(string().matches(REG_EXP.number, "숫자로 입력해 주세요.")),
});

export default function UpdatePlcmtModal(props: {
  plcmt: PopContentsPlcmt;
  serviceList: PopContentsService[];
  onUpdate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { plcmt, serviceList, onUpdate, onClose } = props;

  const toast = useToast();

  const { mutate: updatePlcmt, isLoading: isUpdating } = useUpdatePopContentsPlcmt();

  const initialValues = useMemo<UpdatePopContentsPlcmtFormValues>(
    () => ({
      partner_id: plcmt.partner_id,
      partner_name: plcmt.partner_name,
      media_key: plcmt.media_key,
      media_name: plcmt.media_name,
      id: plcmt.id,
      channel_id: plcmt.channel_id,
      service_id: plcmt.service_id,
      page_id: plcmt.page_id,
      landing_url: plcmt.landing_url,
      ssp_plcmt_ids: plcmt.ssp_plcmt_ids,
      campaign_ids: plcmt.campaign_ids,
    }),
    [plcmt]
  );

  const handleUpdate = ({
    partner_name,
    media_name,
    channel_id,
    ...values
  }: UpdatePopContentsPlcmtFormValues) => {
    updatePlcmt(
      {
        ...values,
        campaign_ids: values.campaign_ids.map((id) => Number(id)),
      },
      {
        onSuccess: () => {
          toast.success("저장되었습니다.");
          onClose();
          onUpdate();
        },
      }
    );
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isValid,
    getFieldProps,
  } = useFormik({
    initialValues,
    onSubmit: handleUpdate,
    validationSchema: updatePlcmtFormSchema,
  });

  const [campaignIdsStr, setCampaignIdsStr] = useState(initialValues.campaign_ids.join(","));

  // 플레이스먼트 목록 조회
  const { data: placementData, isLoading: isPlcmtListLoading } = useGetAppPlacementList({
    mediaKeys: [values.media_key],
    orders: ["name"],
    status: STATUS.ACTIVE,
  });

  const onChangeServiceId = useCallback(
    (event: SelectChangeEvent<PopContentsService["id"]>) => {
      const serviceId = Number(event.target.value);

      if (Number.isInteger(serviceId)) {
        setFieldValue("service_id", serviceId);
      }
    },
    [setFieldValue]
  );

  const onChangePlcmtList = useCallback(
    (values: AppPlacement[]) => {
      setFieldValue(
        "ssp_plcmt_ids",
        values.map((plcmt) => plcmt.id)
      );
    },
    [setFieldValue]
  );

  const onChangeCampaignIds = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setCampaignIdsStr(value);
      setFieldValue(
        "campaign_ids",
        value === ""
          ? []
          : value
              .split(",")
              .map((id) => id.trim())
              .filter((id) => id !== "")
      );
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="update-popcontents-plcmt-modal-title">지면 수정</DialogTitle>
      <DialogContent className="update-popcontents-plcmt-modal-dialog-content">
        <Stack
          component="form"
          id="update-popcontents-plcmt-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <TextField required label="외주사" value={values.partner_name} aria-readonly disabled />

          {/* 앱 매체 명 */}
          <TextField required label="앱 매체" value={values.media_name} aria-readonly disabled />

          <Stack direction="row" alignItems="center" spacing={2}>
            {/* 매체 키 */}
            <TextField required label="매체 키" value={values.media_key} aria-readonly disabled />

            {/* Channel ID */}
            <TextField
              required
              label="Channel ID"
              value={values.channel_id}
              aria-readonly
              disabled
            />
          </Stack>

          {/* 서비스 아이디 */}
          <Select
            label="Service ID"
            value={values.service_id}
            onChange={onChangeServiceId}
            onBlur={handleBlur("service_id")}
            required
          >
            {serviceList.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.desc}
              </MenuItem>
            ))}
          </Select>

          {/* Page ID */}
          <TextField
            required
            label="Page ID"
            placeholder="page ID를 입력해 주세요."
            {...getFieldProps("page_id")}
            error={shouldErrorShows("page_id", touched, errors)}
            helperText={getHelperText("page_id", touched, errors)}
            disabled={isUpdating}
          />

          {/* 랜딩 URL */}
          <TextField
            required
            label="랜딩 URL"
            placeholder="페이지의 URL을 입력해 주세요."
            {...getFieldProps("landing_url")}
            error={shouldErrorShows("landing_url", touched, errors)}
            helperText={getHelperText("landing_url", touched, errors)}
            disabled={isUpdating}
          />

          {/* 플레이스먼트 명 */}
          <MultipleSelect
            className="field"
            options={placementData.placements}
            label="플레이스먼트"
            placeholder="페이지에 연결된 플레이스먼트를 모두 선택해 주세요."
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onChangePlcmtList}
            disabled={placementData.placements.length <= 0 || isPlcmtListLoading}
            value={values.ssp_plcmt_ids
              .map((id) => placementData.placements.find((plcmt) => plcmt.id === id))
              .filter((plcmt): plcmt is AppPlacement => Boolean(plcmt))}
          />

          {/* 캠페인 ID */}
          <TextField
            label="Campaign ID"
            placeholder="Campaign ID를 다수 등록할 경우 comma(,) 로 구분해서 등록해주세요."
            value={campaignIdsStr}
            onChange={onChangeCampaignIds}
            onBlur={handleBlur("campaign_ids")}
            disabled={isUpdating}
            error={shouldErrorShows("campaign_ids", touched, errors)}
            helperText={getHelperText("campaign_ids", touched, errors)}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit">
          취소
        </Button>
        <Button
          type="submit"
          form="update-popcontents-plcmt-modal"
          disabled={!isValid || isUpdating}
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
