import { useParams, useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
import { Container, Typography, Paper, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";

import usePopContentsCsDetail from "src/hooks/apis/popcontents/use-popcontents-cs-detail";

const clientInfoStyle = css`
  .label {
    font-weight: 500;
  }
  .value {
    color: ${grey[600]};
  }
`;

export default function CsClientInfo() {
  const { no } = useParams();
  const navigate = useNavigate();

  const query = usePopContentsCsDetail(
    { no: no as string },
    {
      onError: (error) => {
        if (error.response?.status === 404) {
          navigate("/404", { replace: true });
        }
      },
    }
  );

  return (
    <Container component="section" className="ssp-section" css={clientInfoStyle} maxWidth="xl">
      <Typography className="title" variant="h5" gutterBottom>
        문의자 정보
      </Typography>
      <Paper className="content" elevation={2}>
        <Grid container className="info" spacing={4}>
          <Grid item xs={2} className="label">
            NO
          </Grid>
          <Grid item xs={4} className="value">
            {no}
          </Grid>

          <Grid item xs={2} className="label">
            문의 일자
          </Grid>
          <Grid item xs={4} className="value">
            {query.data ? moment.unix(query.data.created_at).format("YYYY-MM-DD HH:mm:ss") : ""}
          </Grid>

          <Grid item xs={2} className="label">
            앱 매체 명
          </Grid>
          <Grid item xs={4} className="value">
            {query.data ? query.data.media_name : ""}
          </Grid>

          <Grid item xs={2} className="label">
            미디어 키
          </Grid>
          <Grid item xs={4} className="value">
            {query.data ? query.data.media_key : ""}
          </Grid>

          <Grid item xs={2} className="label">
            Channel ID
          </Grid>
          <Grid item xs={4} className="value">
            {query.data?.channel_id ? query.data.channel_id : ""}
          </Grid>

          <Grid item xs={2} className="label">
            Page ID
          </Grid>
          <Grid item xs={4} className="value">
            {query.data?.page_id ? query.data.page_id : ""}
          </Grid>

          <Grid item xs={2} className="label">
            ADID
          </Grid>
          <Grid item xs={4} className="value">
            {query.data?.adid ? query.data.adid : ""}
          </Grid>

          <Grid item xs={2} className="label">
            Email
          </Grid>
          <Grid item xs={4} className="value">
            {query.data ? query.data.email : ""}
          </Grid>

          <Grid item xs={2} className="label">
            USN
          </Grid>
          <Grid item xs={4} className="value">
            {query.data ? query.data.usn : ""}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
