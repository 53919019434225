import { useMemo, useCallback, ChangeEvent, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { object, string, number, array } from "yup";

import { TextField } from "src/components/commons";
import useToast from "src/hooks/useToast";
import useUpdatePopContentPartner from "src/hooks/apis/popcontents/use-update-popcontents-partner";
import { CreatePopContentsPartnerPayload } from "src/hooks/apis/popcontents/use-create-popcontents-partner";
import { PopContentsPartner } from "src/hooks/apis/popcontents/use-popcontents-partner-list";

export const NO_VALUE = -1;

const updatePartnerFormSchema = object({
  // readonly
  id: number().required().notOneOf([NO_VALUE]),
  name: string().required(),
  brn: string().required(),
  commission_rate: number().required(),

  // editable
  white_ips: array().of(string()),
});

type UpdatePopContentsPartnerValues = CreatePopContentsPartnerPayload & {
  id: number;
};

export default function UpdatePartnerModal(props: {
  partner: PopContentsPartner;
  onUpdate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { partner, onUpdate, onClose } = props;

  const toast = useToast();

  const { mutate: updatePartner, isLoading } = useUpdatePopContentPartner();

  const initialValues = useMemo<UpdatePopContentsPartnerValues>(
    () => ({
      id: partner.id,
      name: partner.name,
      brn: partner.brn,
      commission_rate: partner.commission_rate,
      white_ips: partner.white_ips,
    }),
    [partner]
  );

  const handleUpdate = (values: UpdatePopContentsPartnerValues) => {
    updatePartner(values, {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        onClose();
        onUpdate();
      },
    });
  };

  const { values, handleSubmit, setFieldValue, handleBlur, isValid, dirty } = useFormik({
    initialValues,
    onSubmit: handleUpdate,
    validationSchema: updatePartnerFormSchema,
  });

  const [whiteIpsStr, setWhiteIpsStr] = useState(initialValues.white_ips.join(","));

  const onChangeWhiteIps = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setWhiteIpsStr(value);
      setFieldValue(
        "white_ips",
        value === ""
          ? []
          : value
              .split(",")
              .map((ip) => ip.trim())
              .filter((ip) => ip !== "")
      );
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="update-popcontents-partner-modal-title">외주사 등록</DialogTitle>
      <DialogContent className="update-popcontents-partner-modal-dialog-content">
        <Stack
          component="form"
          id="update-popcontents-partner-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 외주사 명 */}
          <TextField label="외주사 명" value={values.name} required readOnly disabled />

          {/* 사업자등록번호 */}
          <TextField label="사업자등록번호" value={values.brn} required readOnly disabled />

          {/* 외주사 수수료율 */}
          <TextField
            label="외주사 수수료율"
            required
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  %
                </Typography>
              ),
            }}
            readOnly
            disabled
            value={String(values.commission_rate)}
            helperText="애드팝콘과 외주사 간의 수수료율 입니다."
          />

          {/* White IP */}
          <TextField
            label="White IP"
            placeholder="white IP를 다수 등록할 경우 comma(,) 로 구분해서 등록해주세요."
            value={whiteIpsStr}
            onChange={onChangeWhiteIps}
            onBlur={handleBlur("white_ips")}
            disabled={isLoading}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit">
          취소
        </Button>
        <Button
          type="submit"
          form="update-popcontents-partner-modal"
          disabled={!isValid || !dirty || isLoading}
        >
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
