import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { PopContentsApp } from "./use-popcontents-app-list";

export type CreatePopContentsAppPayload = Pick<
  PopContentsApp,
  "partner_id" | "channel_id" | "commission_rate" | "company_key" | "endpoint" | "key"
>;

type CreatePopContentsAppMutationKey = "operation/popcontents/create-app";

type CreatePopContentsAppMutationFn = MutationFunction<AxiosResponse, CreatePopContentsAppPayload>;

type CreatePopContentsAppMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, CreatePopContentsAppPayload>,
  "mutationKey" | "mutationFn"
>;

export default function useCreatePopContentsApp(options?: CreatePopContentsAppMutationOptions) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<CreatePopContentsAppMutationKey>(
    () => "operation/popcontents/create-app",
    []
  );

  const mutateFn = useCallback<CreatePopContentsAppMutationFn>((values) => {
    const cookie = parseCookies();
    const { partner_id, ...payload } = values;

    return API.consoleV3.post(`/v3/popcontents/partners/${partner_id}/media`, payload, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
