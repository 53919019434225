import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type PopContentsApp = {
  channel_id: number;
  commission_rate: number;
  company_key: string;
  company_name: string;
  created_at: string;
  endpoint: {
    method: string;
    url: string;
  };
  key: string;
  name: string;
  partner_id: number;
  plcmt_cnt: number;
  partner_name: string;
  updated_at: string;
};

export type PopContentsAppFields = keyof PopContentsApp | `-${keyof PopContentsApp}`;

export type PopContentsAppListParams = {
  partner_id?: number; // 없으면 전체 조회
  company_key?: string;
  query?: string;
  page_no?: number;
  page_size?: number;
  orders?: PopContentsAppFields[];
};

type PopContentsAppListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: PopContentsApp[];
  };
};

type PopContentsAppListQueryKey = ["operation/popcontents/app-list", PopContentsAppListParams];

type PopContentsAppListQueryFn = QueryFunction<
  AxiosResponse<PopContentsAppListData>,
  PopContentsAppListQueryKey
>;

type PopContentsAppListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsAppListData>,
    AxiosError,
    AxiosResponse<PopContentsAppListData>,
    PopContentsAppListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsAppListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function usePopContentsAppList(
  params: PopContentsAppListParams,
  options?: PopContentsAppListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsAppListQueryKey>(
    () => ["operation/popcontents/app-list", params],
    [params]
  );

  const queryFn = useCallback<PopContentsAppListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/popcontents/media", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
