import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Stack } from "@mui/material";
import { CellClickedEvent, ColDef, SortChangedEvent } from "ag-grid-community";
import moment from "moment";
import { parse, stringify } from "qs";
import { mixed, object, string } from "yup";

import { BasicTable, SearchField } from "src/components/commons";
import usePopContentPartnerList, {
  PopContentsPartner,
  PopContentsPartnerFields,
  PopContentsPartnerListParams,
} from "src/hooks/apis/popcontents/use-popcontents-partner-list";
import useOpenModal from "src/hooks/useOpenModal";

import { SEARCH_PARAMS_VALIDATOR } from "../const";
import CreatePartnerModal from "./CreatePartnerModal";
import UpdatePartnerModal from "./UpdatePartnerModal";

const defaultSearchParams: PopContentsPartnerListParams = {
  query: "",
  page_no: 1,
  page_size: 20,
  orders: ["-created_at"],
};

const searchParamsValidationSchema = object({
  query: string(),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidOrders", SEARCH_PARAMS_VALIDATOR.orders),
});

const columnDef: ColDef[] = [
  {
    headerName: "업체 명",
    field: "name",
    sortable: true,
  },
  {
    headerName: "사업자등록번호",
    field: "brn",
    sortable: true,
  },
  {
    headerName: "외주사 키",
    field: "key",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 수",
    field: "plcmt_cnt",
    sortable: true,
  },
  {
    headerName: "White IPs",
    field: "white_ips",
    sortable: false,
    valueFormatter: (params) => {
      const ipList = params.value as string[];
      return ipList.join(", ");
    },
  },
  {
    headerName: "등록일",
    field: "created_at",
    sort: "desc",
    sortable: true,
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD");
    },
  },
];

export default function Partners() {
  const [selectedPartner, setSelectedPartner] = useState<PopContentsPartner | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState<PopContentsPartnerListParams>(() => {
    const parsedSearch = parse(location.search, { ignoreQueryPrefix: true, comma: true });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        query: validatedParams.query || defaultSearchParams.query,
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      };
    } catch (error) {
      return defaultSearchParams;
    }
  });

  const [tempQuery, setTempQuery] = useState(params.query || "");

  const partnerListQuery = usePopContentPartnerList(params);

  useEffect(() => {
    const searchParams = stringify(params, {
      addQueryPrefix: true,
      arrayFormat: "comma",
    });

    navigate("/operation/popcontents/partners" + searchParams, {
      replace: true,
    });
  }, [params, navigate]);

  const handleChangeQuery = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTempQuery(e.target.value);
  }, []);

  const handleSearchQuery = useCallback(() => {
    setParams((prev) => ({ ...prev, query: tempQuery, page_no: 1 }));
  }, [tempQuery]);

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) =>
        sort === "desc" ? `-${colId}` : colId
      ) as PopContentsPartnerFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: partnerListQuery.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [
      handleChangePage,
      handleChangePageSize,
      partnerListQuery.data.pages,
      params.page_no,
      params.page_size,
    ]
  );

  const [openCreatePartnerModal, onShowCreatePartnerModal, onCloseCreatePartnerModal] =
    useOpenModal(null);

  const [openUpdatePartnerModal, onShowUpdatePartnerModal, onCloseUpdatePartnerModal] =
    useOpenModal(null);

  const handleOpenUpdatePartnerModal = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();

      if (e.colDef.field !== "activated" && e.colDef.field !== "id") {
        onShowUpdatePartnerModal(e.event, null);
        setSelectedPartner(e.data);
      }
    },
    [onShowUpdatePartnerModal]
  );

  const handleCloseUpdatePartnerModal = useCallback(() => {
    onCloseUpdatePartnerModal();
    setSelectedPartner(null);
  }, [onCloseUpdatePartnerModal]);

  const handleRefetchPartnerList = useCallback(async () => {
    await partnerListQuery.refetch();
  }, [partnerListQuery]);

  return (
    <Box sx={{ mt: "2rem" }}>
      <Stack
        className="ssp-tools"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <SearchField
          sx={{ width: 400 }}
          label="검색"
          placeholder="업체 명으로 검색 가능합니다."
          value={tempQuery}
          onChange={handleChangeQuery}
          onClickSearchButton={handleSearchQuery}
        />
        <Button variant="outlined" onClick={onShowCreatePartnerModal}>
          외주사 등록
        </Button>
      </Stack>
      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={partnerListQuery.data.rows}
        columnDefs={columnDef}
        {...paginationInfo}
        onCellClicked={handleOpenUpdatePartnerModal}
        onSortChanged={handleSortChange}
      />
      {openCreatePartnerModal.isOpen && (
        <CreatePartnerModal
          onCreate={handleRefetchPartnerList}
          onClose={onCloseCreatePartnerModal}
        />
      )}
      {openUpdatePartnerModal.isOpen && selectedPartner && (
        <UpdatePartnerModal
          partner={selectedPartner}
          onUpdate={handleRefetchPartnerList}
          onClose={handleCloseUpdatePartnerModal}
        />
      )}
    </Box>
  );
}
