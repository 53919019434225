import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { QueryFunction, UseQueryOptions, useQuery } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";

export type PopContentsPlcmt = {
  id: string;
  campaign_ids: number[];
  created_at: number;
  landing_url: string;
  media_key: string;
  media_name: string;
  page_id: string;
  partner_id: number;
  partner_name: string;
  service_id: number;
  service_name: string;
  ssp_plcmt_ids: string[];
  updated_at: number;
  channel_id: number;
};

export type PopContentsPlcmtFields = keyof PopContentsPlcmt | `-${keyof PopContentsPlcmt}`;

export type PopContentsPlcmtListParams = {
  partner_id?: number; // 없으면 전체 조회
  company_key?: string;
  media_key?: string;
  query?: string;
  page_no?: number;
  page_size?: number;
  orders?: PopContentsPlcmtFields[];
};

type PopContentsPlcmtListData = {
  code: number;
  text: string;
  data: {
    page_no: number;
    page_size: number;
    pages: number;
    rows: PopContentsPlcmt[];
  };
};

type PopContentsPlcmtListQueryKey = [
  "operation/popcontents/plcmt-list",
  PopContentsPlcmtListParams
];

type PopContentsPlcmtListQueryFn = QueryFunction<
  AxiosResponse<PopContentsPlcmtListData>,
  PopContentsPlcmtListQueryKey
>;

type PopContentsPlcmtListQueryOptions = Omit<
  UseQueryOptions<
    AxiosResponse<PopContentsPlcmtListData>,
    AxiosError,
    AxiosResponse<PopContentsPlcmtListData>,
    PopContentsPlcmtListQueryKey
  >,
  "queryKey" | "queryFn"
>;

const defaultData: PopContentsPlcmtListData = {
  code: 200,
  text: "OK",
  data: {
    page_no: 1,
    page_size: 1,
    pages: 1,
    rows: [],
  },
};

export default function usePopContentsPlcmtList(
  params: PopContentsPlcmtListParams,
  options?: PopContentsPlcmtListQueryOptions
) {
  const { handleError } = useApiError();

  const queryKey = useMemo<PopContentsPlcmtListQueryKey>(
    () => ["operation/popcontents/plcmt-list", params],
    [params]
  );

  const queryFn = useCallback<PopContentsPlcmtListQueryFn>((context) => {
    const [, params] = context.queryKey;
    const cookie = parseCookies();

    return API.consoleV3.get("/v3/popcontents/placements", {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
      params,
    });
  }, []);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, {
    onError: handleError,
    ...options,
  });

  const data = useMemo(() => {
    return response?.data ? response.data.data : defaultData.data;
  }, [response]);

  return {
    data,
    queryKey,
    ...rest,
  };
}
