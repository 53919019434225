import { useNavigate } from "react-router-dom";

import { Button, Container, Stack } from "@mui/material";

import CsClientInfo from "./CsClientInfo";
import ResponseForm from "./ResponseForm";
import TransactionHistory from "./TransactionHistory";

export default function CsDetail() {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={6}>
        <CsClientInfo />
        <ResponseForm />
        <TransactionHistory />
      </Stack>
      <Container className="ssp-section" maxWidth="xl">
        <Stack direction="row" justifyContent="end">
          <Button type="button" variant="outlined" size="large" onClick={handleClickBack}>
            뒤로 가기
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}
