import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Autocomplete,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useCallback, ChangeEvent, SyntheticEvent } from "react";
import useGetCompanies, { CompanyData } from "src/hooks/apis/companies/useGetCompanies";
import useGetAppMediaList, { AppMedia } from "src/hooks/apis/media/useGetAppMediaList";
import useCreateNpayApp from "src/hooks/apis/npay/use-create-npay-app";
import { NpayApp } from "src/hooks/apis/npay/use-npay-app-list";
import useToast from "src/hooks/useToast";
import { COMPANY, STATUS, APP_PLATFORM_ALIAS } from "src/types";
import { shouldErrorShows, getHelperText } from "src/utils/form-helper";
import { NO_VALUE, numberFormat, numberRegex } from "./const";
import { TextField } from "src/components/commons";
import { object, string, number } from "yup";

type CreateNpayAppFormValues = Pick<
  NpayApp,
  "company_key" | "key" | "commission_rate" | "daily_cap" | "point" | "status"
>;

const createNpayAppFormSchema = object({
  company_key: string().required("업체를 선택해주세요."),
  key: string().required("앱 매체를 선택해 주세요."),
  commission_rate: number()
    .required("네이버페이 포인트 수수료율을 입력해 주세요.")
    .notOneOf([NO_VALUE], "네이버페이 포인트 수수료율을 입력해 주세요."),
  daily_cap: number()
    .required("일 한도를 입력해 주세요.")
    .notOneOf([NO_VALUE], "일 한도 값을 입력해 주세요."),
  point: number()
    .required("1회 지급 포인트량을 입력해 주세요.")
    .moreThan(0, "0 이상 값을 입력해 주세요.")
    .notOneOf([NO_VALUE], "1회 지급 포인트량을 입력해 주세요."),
});

export default function CreateNpayAppModal(props: {
  onCreate: () => Promise<void>;
  onClose: VoidFunction;
}) {
  const { onCreate, onClose } = props;

  const toast = useToast();

  const { mutate: createNpayApp, isLoading: isCreating } = useCreateNpayApp();

  const initialValues = useMemo<CreateNpayAppFormValues>(
    () => ({
      company_key: "",
      key: "",
      commission_rate: 0,
      daily_cap: 0,
      point: 1,
      status: 10,
    }),
    []
  );

  const handleCreate = (values: CreateNpayAppFormValues) => {
    createNpayApp(values, {
      onSuccess: () => {
        toast.success("저장되었습니다.");
        onClose();
        onCreate();
      },
    });
  };

  const { values, errors, touched, handleSubmit, setFieldValue, handleBlur, isValid, setValues } =
    useFormik({
      initialValues,
      onSubmit: handleCreate,
      validationSchema: createNpayAppFormSchema,
    });

  // 회사 목록 조회
  const { data: companyListData } = useGetCompanies({
    types: [
      COMPANY.CORPORATION,
      COMPANY.INDIVIDUAL,
      COMPANY.SYNDICATION,
      COMPANY.SYNDICATION_CLIENT_CORPORATION,
      COMPANY.SYNDICATION_CLIENT_INDIVIDUAL,
    ],
    status: STATUS.ACTIVE,
    pageNo: 1,
    pageSize: 10000,
    orders: ["name"],
  });

  // 앱 매체 목록 조회 (empty string이면 조회 X)
  const { data: appMediaListData } = useGetAppMediaList({
    companyKey: values.company_key,
    disabled: !values.company_key,
  });

  const onChangeValue = useCallback(
    (field: "commission_rate" | "daily_cap" | "point") => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value === "") {
        setFieldValue(field, NO_VALUE);
        return;
      }

      const commaRemoved = value
        .split("")
        .filter((char) => char !== ",")
        .join("");

      const isValidNumber = numberRegex.test(commaRemoved);

      if (isValidNumber) {
        setFieldValue(field, Number(commaRemoved));
      }
    },
    [setFieldValue]
  );

  const onChangeCompany = useCallback(
    (_: SyntheticEvent<unknown>, v: CompanyData | null) => {
      setValues((prev) => ({
        ...prev,
        company_key: v?.key || "",
        key: "",
      }));
    },
    [setValues]
  );

  const onChangeMedia = useCallback(
    (_: SyntheticEvent<unknown>, v: AppMedia | null) => {
      setFieldValue("key", v?.key || "");
    },
    [setFieldValue]
  );

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle id="create-npay-app-modal-title">네이버페이 앱 매체 등록</DialogTitle>
      <DialogContent className="create-npay-app-modal-dialog-content">
        <Stack
          component="form"
          id="create-npay-app-modal"
          pt="20px"
          spacing={2}
          onSubmit={handleSubmit}
        >
          {/* 업체 명 */}
          <Autocomplete
            options={companyListData.companies}
            getOptionLabel={(company) => company.name}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="업체 명"
                placeholder="업체를 선택해주세요."
                required
                error={shouldErrorShows("company_key", touched, errors)}
                helperText={getHelperText("company_key", touched, errors)}
              />
            )}
            value={
              companyListData.companies.find((company) => company.key === values.company_key) ||
              null
            }
            onChange={onChangeCompany}
            onBlur={handleBlur("company_key")}
          />
          {/* 앱 매체 명 */}
          <Autocomplete
            options={appMediaListData.media}
            getOptionLabel={(media) => `[${APP_PLATFORM_ALIAS[media.platform_type]}] ${media.name}`}
            renderInput={({ InputLabelProps, ...params }) => (
              <TextField
                {...params}
                label="앱 매체 명"
                placeholder="업체 선택 후 앱 매체를 선택해 주세요."
                required
                error={shouldErrorShows("key", touched, errors)}
                helperText={getHelperText("key", touched, errors)}
              />
            )}
            value={appMediaListData.media.find((media) => media.key === values.key) || null}
            onChange={onChangeMedia}
            onBlur={handleBlur("key")}
            disabled={!values.company_key}
          />
          {/* 매체 키 */}
          <TextField
            required
            label="매체 키"
            placeholder="업체 선택 후 앱 매체를 선택해 주세요."
            value={values.key}
            aria-readonly
            disabled
          />
          {/* 네이버페이 포인트 수수료율 */}
          <TextField
            required
            label="네이버페이 포인트 수수료율"
            InputProps={{
              sx: { "& > input": { textAlign: "end" } },
              endAdornment: (
                <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                  %
                </Typography>
              ),
            }}
            placeholder="네이버페이 포인트 수수료율을 입력해 주세요."
            value={values.commission_rate === NO_VALUE ? "" : numberFormat(values.commission_rate)}
            onChange={onChangeValue("commission_rate")}
            onBlur={handleBlur("commission_rate")}
            error={shouldErrorShows("commission_rate", touched, errors)}
            helperText={
              getHelperText("commission_rate", touched, errors) ||
              "수수료율은 입력한 당월 부터 반영 됩니다."
            }
            disabled={isCreating}
          />
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              required
              label="일 한도(인당)"
              InputProps={{
                sx: { "& > input": { textAlign: "end" } },
                endAdornment: (
                  <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                    회/일
                  </Typography>
                ),
              }}
              placeholder="일 한도를 입력해 주세요."
              value={values.daily_cap === NO_VALUE ? "" : numberFormat(values.daily_cap)}
              onChange={onChangeValue("daily_cap")}
              onBlur={handleBlur("daily_cap")}
              error={shouldErrorShows("daily_cap", touched, errors)}
              helperText={
                getHelperText("daily_cap", touched, errors) ||
                "일 한도 수정은 수정 즉시 반영 됩니다."
              }
              disabled={isCreating}
            />
            <TextField
              required
              label="1회당 지급 포인트량"
              InputProps={{
                sx: { "& > input": { textAlign: "end" } },
                endAdornment: (
                  <Typography component="span" variant="body2" sx={{ ml: 1, flexShrink: 0 }}>
                    포인트/회
                  </Typography>
                ),
              }}
              placeholder="1회 지급 포인트량을 입력해 주세요."
              value={values.point === NO_VALUE ? "" : numberFormat(values.point)}
              onChange={onChangeValue("point")}
              onBlur={handleBlur("point")}
              error={shouldErrorShows("point", touched, errors)}
              helperText={
                getHelperText("point", touched, errors) ||
                "회당 포인트 지급량은 수정 즉시 반영 됩니다."
              }
              disabled={isCreating}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "1rem" }}>
        <Button type="button" onClick={onClose} color="inherit" disabled={isCreating}>
          취소
        </Button>
        <Button type="submit" form="create-npay-app-modal" disabled={!isValid || isCreating}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
}
