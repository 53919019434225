import { useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";
import { Container, Typography, Paper, Stack, Box, Button } from "@mui/material";
import { SortChangedEvent, ColDef } from "ag-grid-community";
import { useFormik } from "formik";
import moment, { Moment } from "moment";
import { object, mixed } from "yup";

import { DateField, BasicTable } from "src/components/commons";
import useToast from "src/hooks/useToast";
import { shouldErrorShows } from "src/utils/form-helper";
import usePopContentsTransactionHistory, {
  PopContentsTransactionHistoryParams,
  PopContentsTransactionStatus,
} from "src/hooks/apis/popcontents/use-popcontents-cs-transaction-history";

import { MIN_DATE, CS_MAX_DATE } from "../const";

type FormValues = {
  since: Moment | null;
  until: Moment | null;
};

const formValidationSchema = object({
  since: mixed().nullable(),
  until: mixed().nullable(),
});

const initialFormValues: FormValues = {
  since: null,
  until: null,
};

const transactionHistoryStyle = css`
  .label {
    font-weight: 500;
  }
  .value {
    color: ${grey[600]};
  }
`;

// use clientInfoStyle
export default function TransactionHistory() {
  const { no } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [params, setParams] = useState<PopContentsTransactionHistoryParams>({
    id: no as string,
    since: initialFormValues.since?.format("YYYYMMDD") || "",
    until: initialFormValues.until?.format("YYYYMMDD") || "",
    page_no: 1,
    page_size: 10000,
    orders: ["-created_at"],
  });

  // cs 목록 query 조회 여부
  const [enableQuery, setEnableQuery] = useState(true);

  // 조회하기 버튼 핸들러
  const handleSubmitForm = (values: FormValues) => {
    setParams((prev) => ({
      ...prev,
      ...values,
      since: values.since?.format("YYYYMMDD") || "",
      until: values.until?.format("YYYYMMDD") || "",
      page_no: 1,
    }));

    setEnableQuery(true);
  };

  // formik
  const {
    setValues,
    values: formValues,
    isValid,
    errors,
    touched,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmitForm,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const query = usePopContentsTransactionHistory(params, {
    enabled: enableQuery,
    onSuccess: () => {
      setEnableQuery(false);
    },
    onError: (error) => {
      if (error.response?.status === 404) {
        navigate("/404", { replace: true });
      }
    },
  });

  const handleChangeDates = useCallback(
    (field: "since" | "until") => (value: Moment | null) => {
      setValues((prev) => {
        if (value === null) {
          toast.error("날짜를 선택해 주세요.");

          return {
            ...prev,
            [field]: value,
          };
        }

        if (!value.isValid()) {
          return prev;
        }

        const withinRange = value.isSameOrAfter(MIN_DATE) && value.isSameOrBefore(CS_MAX_DATE);

        if (!withinRange) {
          toast.error("선택 가능한 범위를 확인해 주세요.");
          return prev;
        }

        const isEndDate = field === "until";
        const isEndDateBeforeStartDate = isEndDate && value.isBefore(prev.since);
        const isStartDateAfterEndDate = !isEndDate && value.isAfter(prev.until);

        if (isEndDateBeforeStartDate) {
          const newStartDate = moment(value).subtract(6, "d");

          return {
            since: newStartDate.isBefore(MIN_DATE) ? MIN_DATE : newStartDate,
            until: value,
          };
        }

        if (isStartDateAfterEndDate) {
          const newEndDate = moment(value).add(6, "d");

          return {
            since: value,
            until: newEndDate.isAfter(CS_MAX_DATE) ? CS_MAX_DATE : newEndDate,
          };
        }

        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [setValues, toast]
  );

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId));

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
    setEnableQuery(true);
  }, []);

  return (
    <Container
      component="section"
      className="ssp-section"
      css={transactionHistoryStyle}
      maxWidth="xl"
    >
      <Typography className="title" variant="h5" gutterBottom>
        리워드 참여 내역
      </Typography>
      <Paper className="content" elevation={2}>
        <Stack spacing={2}>
          <Stack component="form" onSubmit={handleSubmit} spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* 시작일 */}
                <Box sx={{ width: 300 }}>
                  <DateField
                    label="시작일"
                    minDate={MIN_DATE}
                    maxDate={CS_MAX_DATE}
                    value={formValues.since}
                    onChange={handleChangeDates("since")}
                    InputProps={{
                      onBlur: handleBlur("since"),
                      error: shouldErrorShows("since", touched, errors),
                    }}
                    disabled={query.isFetching}
                  />
                </Box>
                {/* 종료일 */}
                <Box sx={{ width: 300 }}>
                  <DateField
                    label="종료일"
                    minDate={MIN_DATE}
                    maxDate={CS_MAX_DATE}
                    value={formValues.until}
                    onChange={handleChangeDates("until")}
                    InputProps={{
                      onBlur: handleBlur("until"),
                      error: shouldErrorShows("until", touched, errors),
                    }}
                    disabled={query.isFetching}
                  />
                </Box>
                <Button type="submit" variant="contained" disabled={!isValid || query.isFetching}>
                  조회하기
                </Button>
              </Stack>
            </Stack>
          </Stack>

          <BasicTable
            getRowId={(params) => params.data.ad_request_no}
            animateRows
            rowData={query.data.rows}
            columnDefs={columnDef}
            onSortChanged={handleSortChange}
          />
        </Stack>
      </Paper>
    </Container>
  );
}

const columnDef: ColDef[] = [
  {
    headerName: "날짜",
    field: "created_at",
    sortable: true,
    sort: "desc",
    valueFormatter: (params) => {
      return moment.unix(params.value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    headerName: "성공여부",
    field: "status",
    sortable: true,
    valueFormatter: (params) => {
      return PopContentsTransactionStatus[params.value];
    },
  },
  {
    headerName: "미디어 키",
    field: "media_key",
    sortable: true,
  },
  {
    headerName: "매체 명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "Channel ID",
    field: "channel_id",
    sortable: true,
  },
  {
    headerName: "Page ID",
    field: "page_id",
    sortable: true,
  },
  {
    headerName: "Service ID",
    field: "service_id",
    sortable: true,
  },
  {
    headerName: "팝콘텐츠 UID",
    field: "plcmt_id",
    sortable: true,
  },
  {
    headerName: "리워드 수량",
    field: "reward",
    sortable: true,
  },
];
