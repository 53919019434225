import { Box, Stack, Button, SelectChangeEvent, MenuItem } from "@mui/material";
import { CellClickedEvent, ColDef, SortChangedEvent } from "ag-grid-community";
import { parse, stringify } from "qs";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchField, BasicTable, Select } from "src/components/commons";
import useNpayPlacementList, {
  NpayPlacement,
  NpayPlacementFields,
  NpayPlacementListParams,
} from "src/hooks/apis/npay/use-npay-placements";
import useOpenModal from "src/hooks/useOpenModal";
import { Campaign, CAMPAIGN_ALIAS, STATUS, Status } from "src/types";
import { mixed, object, string } from "yup";
import { SEARCH_PARAMS_VALIDATOR } from "../const";
import UpdateNpayPlacementModal from "./UpdateNpayPlacementModal";
import CreateNpayPlacementModal from "./CreateNpayPlacementModal";

const defaultSearchParams: NpayPlacementListParams = {
  media_key: "all",
  query: "",
  page_no: 1,
  page_size: 20,
  orders: ["name"],
};

const searchParamsValidationSchema = object({
  media_key: string().required(),
  query: string(),
  status: string().oneOf([`${STATUS.ACTIVE}`, `${STATUS.SUSPEND}`]),
  page_no: string().test("isValidPageNo", SEARCH_PARAMS_VALIDATOR.pageNo),
  page_size: string().test("isValidPageSize", SEARCH_PARAMS_VALIDATOR.pageSize),
  orders: mixed().test("isValidOrders", SEARCH_PARAMS_VALIDATOR.orders),
});

const columnDef: ColDef[] = [
  {
    headerName: "업체 명",
    field: "company_name",
    sortable: true,
  },
  {
    headerName: "앱 매체 명",
    field: "media_name",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 명",
    field: "name",
    sort: "asc",
    sortable: true,
  },
  {
    headerName: "플레이스먼트 ID",
    field: "id",
    sortable: true,
  },
  {
    headerName: "광고 형식",
    field: "ad_type",
    sortable: true,
    valueFormatter: (params) => {
      return CAMPAIGN_ALIAS[params.value as Campaign];
    },
  },
  {
    headerName: "상태",
    field: "status",
    sortable: true,
    valueFormatter: (params) => {
      return params.value === 10 ? "ON" : "OFF";
    },
  },
];

export default function Placements() {
  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState(() => {
    const parsedSearch = parse(location.search, { ignoreQueryPrefix: true, comma: true });

    try {
      const validatedParams = searchParamsValidationSchema.validateSync(parsedSearch);

      return {
        media_key: validatedParams.media_key,
        query: validatedParams.query || defaultSearchParams.query,
        status: validatedParams.status
          ? Number(validatedParams.status)
          : defaultSearchParams.status,
        page_no: validatedParams.page_no
          ? Number(validatedParams.page_no)
          : defaultSearchParams.page_no,
        page_size: validatedParams.page_size
          ? Number(validatedParams.page_size)
          : defaultSearchParams.page_size,
        orders: Array.isArray(validatedParams.orders)
          ? validatedParams.orders
          : typeof validatedParams.orders === "string"
          ? validatedParams.orders.split(",")
          : defaultSearchParams.orders,
      } as NpayPlacementListParams;
    } catch (error) {
      return defaultSearchParams;
    }
  });

  const [tempQuery, setTempQuery] = useState(params.query || "");
  const [selectedPlcmt, setSelectedPlcmt] = useState<NpayPlacement | null>(null);

  const npayPlcmtListQuery = useNpayPlacementList(params);

  useEffect(() => {
    const searchParams = stringify(params, { addQueryPrefix: true, arrayFormat: "comma" });

    navigate("/operation/npay/placements" + searchParams, {
      replace: true,
    });
  }, [params, navigate]);

  const handleChangeQuery = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTempQuery(e.target.value);
  }, []);

  const handleSearchQuery = useCallback(() => {
    setParams((prev) => ({ ...prev, query: tempQuery, page_no: 1 }));
  }, [tempQuery]);

  const handleChangePage = useCallback((page: number) => {
    setParams((prev) => ({ ...prev, page_no: page }));
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setParams((prev) => ({ ...prev, page_no: 1, page_size: pageSize }));
  }, []);

  const handleChangeStatus = useCallback((e: SelectChangeEvent<Status | 0>) => {
    const { value } = e.target;

    const status = Number(value) as Status;

    setParams((prev) => ({
      ...prev,
      page_no: 1,
      status: status === STATUS.ACTIVE || status === STATUS.SUSPEND ? status : undefined,
    }));
  }, []);

  const handleSortChange = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter((column) => Boolean(column.sort))
      .map(({ colId, sort }) => (sort === "desc" ? `-${colId}` : colId)) as NpayPlacementFields[];

    setParams((prev) => ({ ...prev, page_no: 1, orders: sortedColumns }));
  }, []);

  const paginationInfo = useMemo(
    () => ({
      pagination: {
        page: params.page_no as number,
        count: npayPlcmtListQuery.data.pages,
        onChange: handleChangePage,
      },
      pageSize: {
        size: params.page_size as number,
        onChange: handleChangePageSize,
        options: [20, 30, 50, 100],
      },
    }),
    [
      handleChangePage,
      handleChangePageSize,
      npayPlcmtListQuery.data.pages,
      params.page_no,
      params.page_size,
    ]
  );

  const statusOptions = useMemo(
    () => [
      { label: "전체 상태", value: 0 },
      { label: "ON", value: STATUS.ACTIVE },
      { label: "OFF", value: STATUS.SUSPEND },
    ],
    []
  );

  const [openCreateNpayPlcmtModal, onShowCreateNpayPlcmtModal, onCloseCreateNpayPlcmtModal] =
    useOpenModal(null);

  const [openUpdateNpayPlcmtModal, onShowUpdateNpayPlcmtModal, onCloseUpdateNpayPlcmtModal] =
    useOpenModal(null);

  const handleOpenUpdateNpayPlcmtModal = useCallback(
    (e: CellClickedEvent) => {
      e.event?.preventDefault();

      if (e.colDef.field !== "activated" && e.colDef.field !== "id") {
        onShowUpdateNpayPlcmtModal(e.event, null);
        setSelectedPlcmt(e.data);
      }
    },
    [onShowUpdateNpayPlcmtModal]
  );

  const handleCloseUpdateNpayPlcmtModal = useCallback(() => {
    onCloseUpdateNpayPlcmtModal();
    setSelectedPlcmt(null);
  }, [onCloseUpdateNpayPlcmtModal]);

  const handleRefetchNpayPlcmtList = useCallback(async () => {
    await npayPlcmtListQuery.refetch();
  }, [npayPlcmtListQuery]);

  return (
    <Box sx={{ mt: "2rem" }}>
      <Stack
        className="ssp-tools"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <SearchField
            sx={{ flexShrink: 0, width: 400 }}
            label="검색"
            placeholder="앱 매체 명, 플레이스먼트 명으로 검색 가능합니다."
            value={tempQuery}
            onChange={handleChangeQuery}
            onClickSearchButton={handleSearchQuery}
          />
          <Select
            sx={{ width: 200 }}
            label="상태"
            onChange={handleChangeStatus}
            value={params.status || 0}
          >
            {statusOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Button variant="outlined" onClick={onShowCreateNpayPlcmtModal}>
          네이버페이 적립 플레이스먼트 등록
        </Button>
      </Stack>
      <BasicTable
        getRowId={(params) => params.data.id}
        animateRows
        rowData={npayPlcmtListQuery.data.rows}
        columnDefs={columnDef}
        {...paginationInfo}
        onCellClicked={handleOpenUpdateNpayPlcmtModal}
        onSortChanged={handleSortChange}
      />
      {openCreateNpayPlcmtModal.isOpen && (
        <CreateNpayPlacementModal
          onUpdate={handleRefetchNpayPlcmtList}
          onClose={onCloseCreateNpayPlcmtModal}
        />
      )}
      {openUpdateNpayPlcmtModal.isOpen && selectedPlcmt && (
        <UpdateNpayPlacementModal
          placement={selectedPlcmt}
          onUpdate={handleRefetchNpayPlcmtList}
          onClose={handleCloseUpdateNpayPlcmtModal}
        />
      )}
    </Box>
  );
}
