import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";

import useApiError from "../useApiError";
import API from "src/utils/api";
import { parseCookies } from "nookies";
import { PopContentsPartner } from "./use-popcontents-partner-list";

export type CreatePopContentsPartnerPayload = Pick<
  PopContentsPartner,
  "name" | "brn" | "commission_rate" | "white_ips"
>;

type CreatePopContentsPartnerMutationKey = "operation/popcontents/create-partner";

type CreatePopContentsPartnerMutationFn = MutationFunction<
  AxiosResponse,
  CreatePopContentsPartnerPayload
>;

type CreatePopContentsPartnerMutationOptions = Omit<
  UseMutationOptions<AxiosResponse, AxiosError, CreatePopContentsPartnerPayload>,
  "mutationKey" | "mutationFn"
>;

export default function useCreatePopContentsPartner(
  options?: CreatePopContentsPartnerMutationOptions
) {
  const { handleError } = useApiError();

  const mutationKey = useMemo<CreatePopContentsPartnerMutationKey>(
    () => "operation/popcontents/create-partner",
    []
  );

  const mutateFn = useCallback<CreatePopContentsPartnerMutationFn>((values) => {
    const cookie = parseCookies();

    return API.consoleV3.post("/v3/popcontents/partners", values, {
      headers: {
        "x-api-type": "1",
        Authorization: `Bearer ${cookie.access_token || ""}`,
      },
    });
  }, []);

  const mutation = useMutation(mutationKey, mutateFn, {
    onError: handleError,
    ...options,
  });

  return mutation;
}
